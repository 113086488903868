import React, { useState } from 'react';
import axios from 'axios';
import getEnvConfig from '../../../config'; // This is your custom configuration method for the backend URL
import MainNavbar from '../../../Components/Navbars/MainNavbar';
import Footer from '../../../Components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons'; // Importing the phone icon
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';

const BusinessSupport = () => {
    const { backendUrl } = getEnvConfig();
    const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' });
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);

      try {
        const response = await axios.post(`${backendUrl}/support/contact_business_general`, formData);

        if (response.data.success) {
          alert('Message sent successfully');
        } else {
          alert('Failed to send message');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        alert('There was an error submitting the form');
      } finally {
        setLoading(false);
      }
    };

    return (
      <div style={{ display: 'flex' }}>
         
  
        <Container fluid style={{ marginLeft: '5px' }}>
        <MainNavbar />
          <Row className="justify-content-center my-5">
            <Col md={8}>
              <Card className="shadow-sm p-4">
                <Card.Header className="text-center">
                  <h3>Contact Us</h3>
                  <p>Feel free to get in touch with us if you have any questions or concerns.</p>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="name" className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>

                    <Form.Group controlId="email" className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>

                    <Form.Group controlId="phone" className="mb-3">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="tel"
                        name="phone"
                        placeholder="Your Phone Number"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="message" className="mb-4">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        name="message"
                        placeholder="Your Message"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>

                    <Button variant="primary" type="submit" className="w-100" disabled={loading}>
                      {loading ? 'Sending...' : 'Submit'}
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
            {/* New Card for Contact Phone Number */}
            <Row className="justify-content-center my-4">
            <Col md={8}>
              <Card className="shadow-sm p-4 text-center">
                <Card.Body>
                  <FontAwesomeIcon icon={faPhone} size="2x" className="text-primary mb-2" />
                  <h4>Contact Us by Phone</h4>
                  <p className="lead">You can also reach us by phone at:</p>
                  {/* Click-to-call link */}
                  <h3 className="text-primary">
                    <a href="tel:+15036736280" className="text-primary">
                      <FontAwesomeIcon icon={faPhone} /> +1 503-673-6280
                    </a>
                  </h3>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Footer />
        </Container>
        
      </div>
    );
};

export default BusinessSupport;
