import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Sidebar from '../../../../Components/Sidebar';


function ChargerQRCodeDisplay() {
  const location = useLocation();
  const [uuid, setUUID] = useState(location.state.uuid); 
  const [imageData, setImg] = useState(location.state.imageData);

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <Container fluid style={{ marginLeft: '5px' }}>
        <Row className="justify-content-center my-5">
          <Col md={8}>
            <h2 className="mb-4 text-center">Charger QR Code Display</h2>
            <Card>
              <Card.Body>
                <Row className="mb-3">
                  <Col>
                    <h5>UUID:</h5>
                    <p>{uuid}</p>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <h5>QR Code</h5>
                    {imageData && <img src={imageData} alt="QR Code" />}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ChargerQRCodeDisplay;
