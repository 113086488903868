import React from 'react';
import './ToggleSwitch.css';

const ToggleSwitch = ({ label, isChecked, onChange }) => {
  return (
    <div className="toggle-switch-container">
      <div className="toggle-switch-label-text">{label}</div>
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="toggle-switch-checkbox"
          id={`toggleSwitch`}
          checked={isChecked}
          onChange={onChange}
        />
        <label className="toggle-switch-label" htmlFor={`toggleSwitch`}>
          <span className={`toggle-switch-inner ${isChecked ? 'checked' : ''}`} />
          <span className="toggle-switch-switch" />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
