import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Form, InputGroup } from 'react-bootstrap';

import getEnvConfig from "../../../../config";

function PhoneVerification() {
    const { backendUrl } = getEnvConfig();
    const location = useLocation();
    const navigate = useNavigate();
  
    const [phone, setPhone] = useState(location.state.phone);
    const [email, setEmail] = useState(location.state.email);
    const [isEditing, setIsEditing] = useState(false);
    const [otp, setOtp] = useState('');
    const [modalContent, setModalContent] = useState('');
  
    const handleEditPhone = async () => {
        try {
            await axios.get(`${backendUrl}/users/phone/edit/${email}/${phone}`);
        } catch (error) {
            console.error('Error editing phone number:', error);
        }
    };
  
    const handleGenerateOtp = async () => {
        try {
            const response = await axios.get(`${backendUrl}/users/generate_otp/${phone}`);
            //setOtp(response.data.validation); // Assuming validation contains the OTP
        } catch (error) {
            console.error('Error generating OTP:', error);
        }
    };
  
    const handleVerifyPhone = async () => {
        try {
            const response = await axios.get(`${backendUrl}/users/phone/verify/${otp}/${phone}`);
            if (response.data.verification_check === 'approved') {
                alert('Phone number verified successfully!');
                navigate('/pages/account/settings');
            } else {
                alert(`Error: ${response.data.verification_check}`);
            }
        } catch (error) {
            console.error('Error verifying phone:', error);
            setModalContent('Error occurred: ' + error.message);
        }
    };
  
    return (
 
            <Container fluid className="mt-4">
                <Row>
                    <Col lg={9}>
                        <Card className="p-3">
                            <Card.Body>
                                <h5>Phone Verification</h5>
                                <hr />
                                <Form>
                                    <Form.Group className="mb-3" controlId="formPhone">
                                        <Form.Label>SMS Number</Form.Label>
                                        <InputGroup>
                                            {isEditing ? (
                                                <Form.Control
                                                    type="text"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                />
                                            ) : (
                                                <Form.Control
                                                    plaintext
                                                    readOnly
                                                    defaultValue={phone}
                                                />
                                            )}

                                        </InputGroup>
                                    </Form.Group>
                                    <Button variant="primary" onClick={handleGenerateOtp}>
                                        Generate OTP
                                    </Button>
                                    <hr />
                                    <Form.Group className="mb-3" controlId="formOtp">
                                        <Form.Label>OTP Code</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={otp}
                                            onChange={(e) => setOtp(e.target.value)}
                                            placeholder="Enter OTP"
                                        />
                                    </Form.Group>
                                    <Button variant="primary" onClick={handleVerifyPhone}>
                                        Verify
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

    );
}

export default PhoneVerification;
