import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button, Modal } from 'react-bootstrap'; // Added Modal here
import MainNavbar from '../../../../Components/Navbars/MainNavbar';
import Footer from '../../../../Components/Footer';
import getEnvConfig from "../../../../config";
import validator from 'validator';

const SignUp = () => {
  const navigate = useNavigate();
  const { backendUrl } = getEnvConfig();
  const [message, setMessage] = useState("");
  const [agreement, setAgreement] = useState(true);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    phone_number: "",
    first_name: "",
    last_name: "",
    address: "",
    unit: "",
    city: "",
    state: "Select State",
    country: "Select Country",
    zipcode: ""
  });
  const [isModalOpen, setModalOpen] = useState(false); // Modal state
  const [modalContent, setModalContent] = useState('');

  const openModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalContent('');
  };


  const handleSetAgreement = () => setAgreement(!agreement);

  const handleCountryChange = (e) => {
    setFormData({
      ...formData,
      country: e.target.value // Corrected to access e.target.value
    });
  };

  const handleStateChange = (e) => {
    setFormData({
      ...formData,
      state: e.target.value // Corrected to access e.target.value
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'email' && !validator.isEmail(value)) {
      setMessage("Please, enter valid Email!");
    } else if (name === 'phone_number' && !validator.isMobilePhone(value)) {
      setMessage("Please, enter valid Phone!");
    } else {
      setMessage("");
    }

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${backendUrl}/users/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const reply = await response.json();

      if (response.ok && reply['res'] === 'created') {
        openModal('Registration successful! Please check your email for verification message.');
        setTimeout(() => {
          closeModal();
          navigate("/dashboards/home");
        }, 3000);
      } else {
        openModal('Error: ' + JSON.stringify(reply['res']));
      }
    } catch (error) {
      console.error("Error:", error);
      openModal('Error: ' + error.message);
    }
  };

  return (
    <Container fluid={true}>
      <MainNavbar />
      <Row className="justify-content-center my-5">
        <Col md={8} lg={6}>
          <Card className="p-4 shadow-sm">
            <h4 className="mb-3">Sign Up</h4>
            <Form onSubmit={submitHandler}>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control 
                  type="email" 
                  placeholder="Email, we will validate" 
                  name="email" 
                  value={formData.email} 
                  onChange={handleChange} 
                />
              </Form.Group>
              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control 
                  type="password" 
                  placeholder="Password" 
                  name="password" 
                  value={formData.password} 
                  onChange={handleChange} 
                />
              </Form.Group>
              <Form.Group controlId="formFirstName" className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="First Name" 
                  name="first_name" 
                  value={formData.first_name} 
                  onChange={handleChange} 
                />
              </Form.Group>
              <Form.Group controlId="formLastName" className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="Last Name" 
                  name="last_name" 
                  value={formData.last_name} 
                  onChange={handleChange} 
                />
              </Form.Group>
              <Form.Group controlId="formPhoneNumber" className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control 
                  type="tel" 
                  placeholder="10 Digit Phone Number, we will validate" 
                  name="phone_number" 
                  value={formData.phone_number} 
                  onChange={handleChange} 
                />
              </Form.Group>
              <Form.Group controlId="formAddress" className="mb-3">
                <Form.Label>Address</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="Address Street" 
                  name="address" 
                  value={formData.address} 
                  onChange={handleChange} 
                />
              </Form.Group>
              <Form.Group controlId="formUnit" className="mb-3">
                <Form.Label>Unit</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="Unit" 
                  name="unit" 
                  value={formData.unit} 
                  onChange={handleChange} 
                />
              </Form.Group>
              <Form.Group controlId="formCity" className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="City" 
                  name="city" 
                  value={formData.city} 
                  onChange={handleChange} 
                />
              </Form.Group>
              <Form.Group controlId="formState" className="mb-3">
                <Form.Label>State</Form.Label>
                <Form.Select 
                  name="state" 
                  value={formData.state} 
                  onChange={handleStateChange}
                >
                  <option value="Select State">Select State</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="formCountry" className="mb-3">
                <Form.Label>Country</Form.Label>
                <Form.Select 
                  name="country" 
                  value={formData.country} 
                  onChange={handleCountryChange}
                >
                  <option value="Select Country">Select Country</option>
                  <option value="us">United States</option>
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="formZipCode" className="mb-3">
                <Form.Label>Zip Code</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="Zip Code" 
                  name="zipcode" 
                  value={formData.zipcode} 
                  onChange={handleChange} 
                />
              </Form.Group>
              <Form.Group controlId="formAgreement" className="mb-3">
                <Form.Check 
                  type="checkbox" 
                  label="I agree to the Terms and Conditions" 
                  checked={agreement} 
                  onChange={handleSetAgreement} 
                />
              </Form.Group>
              <Button variant="primary" type="submit" block>
                Sign Up
              </Button>
            </Form>
            <div className="mt-3 text-center">
              <span>Already have an account? <Link to="/authentication/sign-in/basic">Sign in</Link></span>
            </div>
          </Card>
        </Col>
      </Row>
      {/* Modal for displaying messages */}
      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>      
      <Footer />
    </Container>
  );
};

export default SignUp;
