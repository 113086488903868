import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import MainNavbar from '../../../Components/Navbars/MainNavbar';
import Footer from '../../../Components/Footer';

const BusinessResourcesGuidances = () => {
    return (
        <Container fluid={true}>
          <MainNavbar />
          <Row className="justify-content-center my-5">
            <Col md={10}>
              <h2 className="mb-4 text-center">Resources & Guidance for Property Managers and Parking Owners</h2>
    
              <Row className="mb-4">
                <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
                  <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div>
                      <h3 className="display-6 fw-bold lh-4 mb-3">Introduction</h3>
                      <p className="lead">
                        As electric vehicle (EV) adoption continues to rise, property managers and parking owners have a unique opportunity to turn parking lots into productive assets. This page provides essential guidance and resources to help you navigate the process of installing EV chargers and maximizing the potential earnings from your existing infrastructure.
                      </p>
                    </div>
                  </div>
                </div>
              </Row>

              {/* Government Aid Section */}
              <Row className="mb-4">
                <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
                  <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div className="col-10 col-sm-8 col-lg-6">
                      <img src="/images/government_aid.webp" className="d-block mx-lg-auto img-fluid" style={{ maxWidth: "100%", maxHeight: "450px" }} alt="Government Aid" loading="lazy"/>
                    </div>
                    <div className="col-lg-6">
                      <h3 className="display-6 fw-bold lh-4 mb-3">Government Aid and Grants</h3>
                      <p className="lead">
                        Many federal, state, and local governments provide financial assistance to reduce the capital outlay required for EV charger installations. From grants to tax incentives, these programs are designed to support property owners in enhancing their infrastructure. Taking advantage of these resources can significantly lower your upfront costs, helping you install more chargers and future-proof your property with minimal financial burden. 
                        <strong>Reach out to us</strong> if you need help navigating these government programs.
                      </p>
                    </div>
                  </div>
                </div>
              </Row>

              {/* EV Adoption Section */}
              <Row className="mb-4">
                <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
                  <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div className="col-10 col-sm-8 col-lg-6">
                      <img src="/images/increase_in_adoption.webp" className="d-block mx-lg-auto img-fluid" style={{ maxWidth: "100%", maxHeight: "450px" }} alt="Increase in EV Adoption" loading="lazy"/>
                    </div>
                    <div className="col-lg-6">
                      <h3 className="display-6 fw-bold lh-4 mb-3">Increase in EV Adoption</h3>
                      <p className="lead">
                        The electric vehicle market is expected to grow significantly in the coming years, presenting a lucrative opportunity for parking owners to monetize their existing spaces. By installing EV chargers now, you’re positioning your property to capture additional revenue streams as EV adoption accelerates. As more drivers switch to electric vehicles, the demand for charging stations will only increase, allowing you to generate substantial cash flows from parking spaces that may otherwise sit idle.
                      </p>
                    </div>
                  </div>
                </div>
              </Row>

              {/* Attract Tenants Section */}
              <Row className="mb-4">
                <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
                  <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div className="col-10 col-sm-8 col-lg-6">
                      <img src="/images/attract_tenants.webp" className="d-block mx-lg-auto img-fluid" style={{ maxWidth: "100%", maxHeight: "450px" }} alt="Attract Tenants, Businesses, and Shoppers" loading="lazy"/>
                    </div>
                    <div className="col-lg-6">
                      <h3 className="display-6 fw-bold lh-4 mb-3">Attract Tenants, Businesses, and Shoppers</h3>
                      <p className="lead">
                        Offering EV charging stations at your property makes it more appealing to environmentally conscious tenants, businesses, and shoppers. Whether it's for residential tenants looking for a convenient place to charge or businesses seeking to attract EV drivers to their stores, providing charging infrastructure enhances the overall value of your property. 
                        <strong>With EV Chron's easy-to-install solutions</strong>, your property stands out as a modern, sustainable, and convenient choice for tenants and customers alike.
                      </p>
                    </div>
                  </div>
                </div>
              </Row>

              {/* Selecting the Right Partner */}
              <Row className="mb-4">
                <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
                  <div className="row align-items-center g-5 py-5">
                    <div className="col-lg-6">
                      <h3 className="display-6 fw-bold lh-4 mb-3">Selecting the Right Charging Partner</h3>
                      <p className="lead">
                        When considering a partner for providing EV chargers, it's essential to evaluate several key factors:
                        <ul>
                          <li><strong>Cost-Effectiveness:</strong> Are there any hidden fees? Look for a provider like EV Chron that offers <strong>transparent pricing</strong> and low upfront costs.</li>
                          <li><strong>Scalability:</strong> Can the network grow with your business? EV Chron's platform supports <strong>expansion and scalability</strong> with ease, ensuring you can add more chargers as EV demand increases.</li>
                          <li><strong>Smart Features:</strong> Does the provider offer smart load balancing, real-time monitoring, and revenue reporting? With EV Chron, you gain access to <strong>intelligent software</strong> that helps maximize charger efficiency while avoiding overloads.</li>
                          <li><strong>Revenue Potential:</strong> Make sure your partner offers flexible pricing options. At EV Chron, we empower you to set your own rates, keeping your revenue strategies in your control.</li>
                          <li><strong>Customer Support:</strong> Does the provider offer ongoing support and maintenance? EV Chron provides comprehensive support to ensure your chargers are always functioning at their best.</li>
                        </ul>
                      </p>
                    </div>
                    <div className="col-10 col-sm-8 col-lg-6">
                      <img src="/images/ev_partner_selection.webp" className="d-block mx-lg-auto img-fluid" style={{ maxWidth: "100%", maxHeight: "450px" }} alt="Selecting an EV Charging Partner" />
                    </div>
                  </div>
                </div>
              </Row>

              {/* Compare Us with Competitors */}
              <Row className="mb-4">
                <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
                  <div className="row align-items-center g-5 py-5">
                    <div className="col-lg-12">
                      <h3 className="display-6 fw-bold lh-4 mb-3">Why Choose EV Chron Over Competitors?</h3>
                      <p className="lead">
                        While there are several EV charging solutions available, here’s why EV Chron is the clear choice:
                        <ul>
                          <li><strong>Lower Costs:</strong> Unlike other providers, we offer affordable, networked chargers with no hidden fees.</li>
                          <li><strong>Turnkey Solutions:</strong> We handle everything from installation to payment processing, making it easy for you to get started.</li>
                          <li><strong>Smart Technology:</strong> Our platform features real-time monitoring, load balancing, and advanced data reporting to optimize your operations.</li>
                          <li><strong>Seamless Integration:</strong> EV Chron integrates seamlessly with your property management systems, ensuring smooth operations with minimal disruption.</li>
                          <li><strong>Ongoing Support:</strong> We provide unparalleled customer support to ensure your chargers remain operational and profitable.</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </Row>

            </Col>
          </Row>
          <Footer />
        </Container>
      );
    };

export default BusinessResourcesGuidances;
