import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Divider } from '@mui/material';

function Footer() {
  return (
    <Container fluid={true} style={{ backgroundImage: 'linear-gradient(to right,#F7FAFC,#F7FAFC,#F7FAFC)' }}>
      <Row className="footer-row">
        <Col sm={4} className="text-center">
          <img src="/images/evchron_logo2.png" width="70" height="65" alt="EV Chron" />
          <p className="footerheaderTextBold">EV Chron</p>
          <p className="footerheaderText">Plug, Pay, Propel:</p>
          <p className="footerheaderText">Empower Your Space, Enhance Your Drive</p>
          <div className="footer-social-icons">
            <GoogleIcon />
            <FacebookIcon />
            <TwitterIcon />
            <InstagramIcon />
          </div>
        </Col>
        <Col sm={8}>
          <Row className="footer-links">
            <Col>
                <Nav.Link href="#home" ><p className="navlinkText">Drivers</p></Nav.Link>
              
            </Col>
            <Col>
                <Nav.Link href="#home" ><p className="navlinkText">Business Owners</p></Nav.Link>
              
            </Col>
            <Col>
                <Nav.Link href="#home" ><p className="navlinkText">About Us</p></Nav.Link>
              
            </Col>
            <Col>
                <Nav.Link href="#home" ><p className="navlinkText">Support</p></Nav.Link>
              
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
