import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Card, Modal } from 'react-bootstrap';
import Sidebar from '../../../../Components/Sidebar';
import getEnvConfig from '../.././../../config';
import { fetchToken, clearToken } from '../../../../Auth';

function ChargerRegistration() {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [locations, setLocations] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [creatingNewLocation, setCreatingNewLocation] = useState(false);
  const [creatingNewGroup, setCreatingNewGroup] = useState(false);
  const [usernameSubmitted, setUsernameSubmitted] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const { backendUrl } = getEnvConfig();
  const token = fetchToken();

  const [formData, setFormData] = useState({
    token: token,
    sim_iccid: "",
    serial_number: "",
    charger_model_name: "",
    price_per_kwh: "",
    charging_rate: "",
    charger_amps: "",
    location_id: "",
    group_id: "",
    address: "",
    unit: "",
    city: "",
    state: "",
    country: "US",  // Default country
    zipcode: "",
    group_name: "",
    total_max_amp: "",
    utility_id: "",
    username: "",
  });

  console.log(token);

  const fetchLocations = async (username) => {
    const response = await fetch(`${backendUrl}/chargers/get_locations`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_token: token, username }),
    });
    const data = await response.json();
    setLocations(data.locations || []);
  };

  const handleUsernameSubmit = (e) => {
    e.preventDefault();
    setUsernameSubmitted(true);
    fetchLocations(formData.username);
  };

  useEffect(() => {
    const checkSuperUser = async () => {
      try {
        const response = await fetch(`${backendUrl}/users/check_superuser`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_token: token }),
        });
        const data = await response.json();
        if (data.super_user) {
          setIsSuperUser(true);
        } else {
          alert('Access denied. You are not a superuser.');
          navigate('/userdashboard');
        }
      } catch (error) {
        clearToken();
        navigate('/login');
      }
    };

    if (token) {
      checkSuperUser();
    } else {
      navigate('/login');
    }
  }, [backendUrl, navigate, token]);

  const fetchGroups = async (location_id) => {
    const response = await fetch(`${backendUrl}/chargers/get_groups`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ location_id, user_token: token, username: formData.username }),
    });
    const data = await response.json();
    setGroups(data.groups || []);
  };

  const handleLocationChange = (e) => {
    const location_id = e.target.value;
    setSelectedLocation(location_id);
    fetchGroups(location_id);
    setFormData({ ...formData, location_id });
    setCreatingNewGroup(false);
  };

  const handleGroupChange = (e) => {
    const group_id = e.target.value;
    setSelectedGroup(group_id);
    setFormData({ ...formData, group_id });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const createNewLocation = async () => {
    try {
      const response = await fetch(`${backendUrl}/chargers/create_location`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_token: token,
          address: formData.address,
          unit: formData.unit,
          city: formData.city,
          state: formData.state,
          country: formData.country,
          zipcode: formData.zipcode,
          username: formData.username,
        }),
      });
      const data = await response.json();
      if (data.status === 'success') {
        setSelectedLocation(data.location_id);
        setCreatingNewLocation(false);
        fetchLocations(formData.username);
      } else {
        alert('Failed to create location');
      }
    } catch (error) {
      alert(error);
    }
  };

  const createNewGroup = async () => {
    try {
      console.log({
        user_token: token,
        location_id: selectedLocation,
        group_name: formData.group_name,
        total_max_amp: formData.total_max_amp,
        utility_id: formData.utility_id,
        username: formData.username,
      });
      const response = await fetch(`${backendUrl}/chargers/create_group`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_token: token,
          location_id: selectedLocation,
          group_name: formData.group_name,
          total_max_amp: formData.total_max_amp,
          utility_id: formData.utility_id,
          username: formData.username,
        }),
      });
      const data = await response.json();
      if (data.status === 'success') {
        setSelectedGroup(data.group_id);
        setCreatingNewGroup(false);
        fetchGroups(selectedLocation);
      } else {
        alert('Failed to create group');
      }
    } catch (error) {
      alert(error);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${backendUrl}/chargers/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      const reply = await response.json();

      if (response.ok && reply.res === 'created') {
        openModal('Registration successful!');
        navigate('/users/charger_registration_display', {
          state: {
            imageData: `data:image/png;base64,${reply.img}`,
            uuid: reply.uuid,
          },
        });
      } else {
        openModal('Error: ' + JSON.stringify(reply.res));
      }
    } catch (error) {
      alert(error);
    }
  };

  const openModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalContent('');
  };

  if (!isSuperUser) return null;

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <Container fluid style={{ marginLeft: '5px' }}>
        <Row className="justify-content-center my-5">
          <Col md={8}>
            <h2 className="mb-4 text-center">EVChron Charger Registration!</h2>
            <Card>
              <Card.Body>
                {!usernameSubmitted ? (
                  <Form onSubmit={handleUsernameSubmit}>
                    <Form.Group controlId="username" className="mb-3">
                      <Form.Label>Enter User's Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter user's email"
                        name="username"
                        onChange={handleChange}
                        value={formData.username}
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="w-100">
                      Fetch Locations
                    </Button>
                  </Form>
                ) : (
                  <Form onSubmit={submitHandler}>
                    <Form.Group controlId="location" className="mb-3">
                      <Form.Label>Select Location</Form.Label>
                      <Form.Select name="location_id" onChange={handleLocationChange} value={selectedLocation}>
                        <option>Select Location</option>
                        {locations.map((location) => (
                          <option key={location.id} value={location.id}>
                            {location.address}, {location.city}, {location.state}
                          </option>
                        ))}
                      </Form.Select>
                      <Button variant="link" onClick={() => setCreatingNewLocation(!creatingNewLocation)}>
                        {creatingNewLocation ? 'Cancel' : 'Create New Location'}
                      </Button>
                    </Form.Group>

                    {creatingNewLocation && (
                      <>
                        <Form.Group controlId="address" className="mb-3">
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Address"
                            name="address"
                            onChange={handleChange}
                            value={formData.address}
                          />
                        </Form.Group>
                        <Form.Group controlId="unit" className="mb-3">
                          <Form.Label>Unit</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Unit"
                            name="unit"
                            onChange={handleChange}
                            value={formData.unit}
                          />
                        </Form.Group>
                        <Form.Group controlId="city" className="mb-3">
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="City"
                            name="city"
                            onChange={handleChange}
                            value={formData.city}
                          />
                        </Form.Group>
                        <Form.Group controlId="state" className="mb-3">
                          <Form.Label>State</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="State"
                            name="state"
                            onChange={handleChange}
                            value={formData.state}
                          />
                        </Form.Group>
                        <Form.Group controlId="zipcode" className="mb-3">
                          <Form.Label>Zip Code</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Zip Code"
                            name="zipcode"
                            onChange={handleChange}
                            value={formData.zipcode}
                          />
                        </Form.Group>
                        <Button variant="secondary" onClick={createNewLocation}>
                          Save Location
                        </Button>
                      </>
                    )}

                    <Form.Group controlId="group" className="mb-3">
                      <Form.Label>Select Group</Form.Label>
                      <Form.Select name="group_id" onChange={handleGroupChange} value={selectedGroup}>
                        <option>Select Group</option>
                        {groups.map((group) => (
                          <option key={group.id} value={group.id}>
                            {group.group_name} (Max Amps: {group.total_max_amp})
                          </option>
                        ))}
                      </Form.Select>
                      <Button variant="link" onClick={() => setCreatingNewGroup(!creatingNewGroup)}>
                        {creatingNewGroup ? 'Cancel' : 'Create New Group'}
                      </Button>
                    </Form.Group>

                    {creatingNewGroup && (
                      <>
                        <Form.Group controlId="group_name" className="mb-3">
                          <Form.Label>Group Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Group Name"
                            name="group_name"
                            onChange={handleChange}
                            value={formData.group_name}
                          />
                        </Form.Group>
                        <Form.Group controlId="total_max_amp" className="mb-3">
                          <Form.Label>Total Max Amps</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Total Max Amps"
                            name="total_max_amp"
                            onChange={handleChange}
                            value={formData.total_max_amp}
                          />
                        </Form.Group>
                        <Form.Group controlId="utility_id" className="mb-3">
                          <Form.Label>Utility ID</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Utility ID"
                            name="utility_id"
                            onChange={handleChange}
                            value={formData.utility_id}
                          />
                        </Form.Group>

                        <Button variant="secondary" onClick={createNewGroup}>
                          Save Group
                        </Button>
                      </>
                    )}

                    <Form.Group controlId="sim_iccid" className="mb-3">
                      <Form.Label>SIM Card ICCID</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="SIM Card ICCID"
                        name="sim_iccid"
                        onChange={handleChange}
                        value={formData.sim_iccid}
                      />
                    </Form.Group>
                    <Form.Group controlId="serial_number" className="mb-3">
                      <Form.Label>Charger Serial Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Charger Serial Number"
                        name="serial_number"
                        onChange={handleChange}
                        value={formData.serial_number}
                      />
                    </Form.Group>
                    <Form.Group controlId="charger_model_name" className="mb-3">
                      <Form.Label>Model Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Model Name"
                        name="charger_model_name"
                        onChange={handleChange}
                        value={formData.charger_model_name}
                      />
                    </Form.Group>
                    <Form.Group controlId="price_per_kwh" className="mb-3">
                      <Form.Label>Price Per KWh</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Price Per KWh"
                        name="price_per_kwh"
                        onChange={handleChange}
                        value={formData.price_per_kwh}
                      />
                    </Form.Group>
                    <Form.Group controlId="charger_amps" className="mb-3">
                      <Form.Label>Charger Amps</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Charger Amps"
                        name="charger_amps"
                        onChange={handleChange}
                        value={formData.charger_amps}
                      />
                    </Form.Group>
                    <Form.Group controlId="charging_rate" className="mb-3">
                      <Form.Label>Charging Rate (KWh)</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Charging Rate"
                        name="charging_rate"
                        onChange={handleChange}
                        value={formData.charging_rate}
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="w-100">
                      Register Charger
                    </Button>
                  </Form>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Server Response</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ChargerRegistration;
