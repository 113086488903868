
const config = {
    development: {
        backendUrl: "http://192.168.68.94:8000", // Replace with your local development backend URL
        frontendUrl: "http://localhost:3000", // Replace with your local development frontend URL
    },
    test: {
        backendUrl: "https://backend.quarge.com",
        frontendUrl: "https://quarge.com",
    },
    production: {
        backendUrl: "https://backend.evchron.com",
        frontendUrl: "https://evchron.com",
    },    
};

// Get the custom environment variable or fall back to NODE_ENV
const backend_env = process.env.REACT_APP_BACKEND_ENV || process.env.NODE_ENV || "production";

const getEnvConfig = () => {
    // Check if the environment exists in the config, default to 'production' if not
    return config[backend_env] || config.production;
};

export default getEnvConfig;
