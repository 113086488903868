
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Card, Form, Button,Collapse } from 'react-bootstrap';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Sidebar from '../../../../../Components/Sidebar';
import Footer from '../../../../../Components/Footer';
import getEnvConfig from "../../../../../config";
import { setToken, fetchToken, RequireToken } from '../../../../../Auth';


const CheckoutForm = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = fetchToken();
    /*const chargerid = urlParams.get('chargerid'); */
    const { chargerid } = useParams();
    const navigate = useNavigate();
  
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [clientSecret, setclientSecret] = useState(null);
    const { backendUrl } = getEnvConfig();
    const [taxOpen, setTaxOpen] = useState(false);
    const [chargerDetails, setChargerDetails] = useState(null);

  console.log('Charger ID:', chargerid);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/chargers/get_charger/${chargerid}`);
        setChargerDetails(response.data);
      } catch (error) {
        console.error('Error fetching charger details:', error);
      }
    };

    fetchData();
  }, [backendUrl, chargerid]);

  if (!token) {
    return <RequireToken />;
  }

  const createPaymentIntentOnBackend = async () => {
    try {
      const response = await axios.post(`${backendUrl}/chargers/create-payment-intent`, {
        amount: 5000, // $50 hold
        currency: 'usd',
        charger_id: String(chargerid),
        user_token: token
      });
      return { clientSecret: response.data.clientSecret };
    } catch (error) {
      console.error('Error creating payment intent:', error);
      return { error: 'Error creating payment intent' };
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const { error: backendError, clientSecret } = await createPaymentIntentOnBackend();

      if (backendError) {
        setError(backendError);
        setLoading(false);
        return;
      } else {
        setclientSecret(clientSecret);
      }

      const cardElement = elements.getElement(CardElement);
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (result.error) {
        console.log(result.error.message);
        setError(result.error.message);
      } else {
        if (result.paymentIntent.status === 'requires_capture') {
          console.log('Payment succeeded, charger can be turned on');

          const paymentIntentId = result.paymentIntent.id;
          try {
            await axios.post(`${backendUrl}/chargers/store-payment-intent`, {
              paymentIntentId: paymentIntentId,
              chargerId: chargerid,
              user_token: token,
              clientSecret: clientSecret
            });
            alert('Starting Charging Session');
            navigate('/users/drivers/driver-charging-history');
          } catch (error) {
            console.error('Error storing payment intent:', error);
            alert('Payment Processing Error :' + error);
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while processing the payment');
    }

    setLoading(false);
  };

  if (!chargerDetails) {
    return (


        <div style={{ display: 'flex' }}>
        <Sidebar />
        <Container fluid style={{ marginLeft: '200px' }}> {/* Adjust margin-left as needed */}
        <Row className="justify-content-center my-5">
                <Col md={8} lg={6}>
                    <Card className="p-4 shadow-sm">
                    <h4>Loading Charger Details...</h4>
                    </Card>
                </Col>
                </Row>
        </Container>
        </div>
    );
  }

  return (
    <Container fluid={true}>

<Row className="justify-content-center my-5">
                <Col md={14} lg={10}>
                    <Card className="p-4 shadow-sm">
                        <h4 className="mb-3">Charger Details</h4>
                        <p><strong>Base Price per kWh:</strong> ${chargerDetails.base_price ? chargerDetails.base_price.toFixed(2) : 'N/A'}</p>
                        <p><strong>Service Charge / kWh:</strong> ${chargerDetails.service_charge ? chargerDetails.service_charge.toFixed(2) : 'N/A'}</p>

                        {/* Collapsible Tax Amount Section */}
                        <p>
                            <strong>Tax Amount / kWh :</strong>
                            <Button
                                variant="link"
                                onClick={() => setTaxOpen(!taxOpen)}
                                aria-controls="tax-breakdown"
                                aria-expanded={taxOpen}
                                className="ml-2 p-0"
                            >
                                {chargerDetails.tax_amount ? `$${chargerDetails.tax_amount.toFixed(2)}` : 'N/A'}
                            </Button>
                        </p>
                        <Collapse in={taxOpen}>
                            <div id="tax-breakdown">
                                <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                                    <li><strong>State Tax Rate:</strong> {chargerDetails.state_rate ? `${chargerDetails.state_rate}%` : 'N/A'}</li>
                                    <li><strong>Special Tax Rate:</strong> {chargerDetails.special_rate ? `${chargerDetails.special_rate}%` : 'N/A'}</li>
                                    <li><strong>County Tax Rate:</strong> {chargerDetails.county_rate ? `${chargerDetails.county_rate}%` : 'N/A'}</li>
                                    <li><strong>City Tax Rate:</strong> {chargerDetails.city_rate ? `${chargerDetails.city_rate}%` : 'N/A'}</li>
                                    <li><strong>GAs Tax Rate:</strong> {chargerDetails.gas_eqivalent_rate ? `${chargerDetails.gas_eqivalent_rate}%` : 'N/A'}</li>
                                </ul>
                            </div>
                        </Collapse>

                        <p><strong>Total Price per kWh:</strong> ${chargerDetails.price_per_kwh ? chargerDetails.price_per_kwh.toFixed(2) : 'N/A'}</p>
                        <p><strong>Payment Processing Fee:</strong> 2.9% + $0.30</p>

                        <Form onSubmit={handleSubmit}>
                            <CardElement />
                            <Button 
                                variant="primary" 
                                type="submit" 
                                block 
                                disabled={!stripe || loading} 
                                className="mt-3"
                            >
                                {loading ? 'Processing...' : 'Pay & Turn On Charger'}
                            </Button>
                            {error && <div className="mt-3 text-danger">{error}</div>}
                        </Form>
                    </Card>
                </Col>
            </Row>

    </Container>
  );
};

export default CheckoutForm;
