import React from 'react';
import MainNavbar from '../../../Components/Navbars/MainNavbar';
import Footer from '../../../Components/Footer';
import { Container, Row, Col, CardGroup, Card } from 'react-bootstrap';

const WhyUs = () => {
  return (
    <Container fluid={true}>
      <MainNavbar />

      <Row>
        <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
          <span className="text-center">
            <h4>Why Choose EV Chron?</h4>
            <p>Discover why EV owners in apartments, multi-family dwellings, and offices should choose us.</p>
          </span>
        </div>
      </Row>

      <Row>
        <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
          <CardGroup>
            <Card className="mx-1 align-items-center py-3 px-3">
              <Card.Body>
                <Card.Title>Convenience for Apartment and Multi-Family Residents</Card.Title>
                <Card.Text>
                  Our user-friendly platform allows you to effortlessly locate and book the nearest EV chargers in your apartment complex or multi-family dwelling. With our chargers installed in your living area, charging your vehicle becomes as easy as parking your car.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mx-1 align-items-center py-3 px-3">
              <Card.Body>
                <Card.Title>Office Charging Made Easy</Card.Title>
                <Card.Text>
                  For those working in offices, having our chargers installed means you can conveniently charge your vehicle while you work. This ensures your car is always ready for the commute home or any after-work errands.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mx-1 align-items-center py-3 px-3">
              <Card.Body>
                <Card.Title>Expanding Network, Expanding Options</Card.Title>
                <Card.Text>
                  As we grow our network of chargers, your options for charging your vehicle expand as well. Whether at home, at work, or in public locations, EV Chron ensures you have a convenient charging option wherever you go.
                </Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </div>
      </Row>

      <Row>
        <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
          <div className="row align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-6">
              <img src="/images/convenience.webp" className="d-block mx-lg-auto img-fluid" style={{ maxWidth: "100%", maxHeight: "450px" }} alt="Convenience" height="300" loading="lazy" />
            </div>
            <div className="col-lg-6">
              <h3 className="display-6 fw-bold lh-4 mb-3">Unparalleled Convenience</h3>
              <p className="lead">Our platform allows you to effortlessly locate and book the nearest EV chargers. With real-time status updates and transparent pricing, charging your vehicle is as convenient as possible, whether you're at home, at work, or on the go.</p>
            </div>
          </div>
        </div>
      </Row>



      <Row>
        <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
          <div className="row align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-6">
              <img src="/images/community.webp" className="d-block mx-lg-auto img-fluid" style={{ maxWidth: "100%", maxHeight: "450px" }} alt="Community" height="300" loading="lazy" />
            </div>
            <div className="col-lg-6">
              <h3 className="display-6 fw-bold lh-4 mb-3">Community Impact</h3>
              <p className="lead">By reaching out to your apartment management and encouraging them to install our chargers, you contribute to a greener, more sustainable community. Increased availability of EV chargers promotes the adoption of electric vehicles, reducing carbon emissions and benefiting everyone.</p>
            </div>
          </div>
        </div>
      </Row>


      <Row>
        <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
          <div className="row align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-6">
              <img src="/images/future-proofing.webp" className="d-block mx-lg-auto img-fluid" style={{ maxWidth: "100%", maxHeight: "450px" }} alt="Future Proofing" height="300" loading="lazy" />
            </div>
            <div className="col-lg-6">
              <h3 className="display-6 fw-bold lh-4 mb-3">Future-Proofing</h3>
              <p className="lead">As the EV market continues to grow, having a robust charging infrastructure in place ensures you are prepared for the future. By supporting the expansion of our network, you ensure that you and your community are ready to meet the demands of the growing number of electric vehicles.</p>
            </div>
          </div>
        </div>
      </Row>

      <Footer />
    </Container>
  );
};

export default WhyUs;
