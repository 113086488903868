import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup, Card, Button, Dropdown } from 'react-bootstrap';
import { Line, Bar } from 'react-chartjs-2';
import Sidebar from '../../../../Components/Sidebar';
import getEnvConfig from '../../../../config';
import { fetchToken } from '../../../../Auth';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const BusinessChargingHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [locationGroups, setLocationGroups] = useState({});
  const [expandedLocation, setExpandedLocation] = useState(null);
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [cumulativeData, setCumulativeData] = useState(null);
  const [transactionLevelData, setTransactionLevelData] = useState(null);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState('ALL'); // Default time frame
  const { backendUrl } = getEnvConfig();
  const token = fetchToken();

  useEffect(() => {
    const getChargingSessions = async () => {
      try {
        // Pass the selected time frame to the backend
        const response = await fetch(`${backendUrl}/sessions/Ownerhistory`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            user_token: token,
            time_frame: selectedTimeFrame, // Ensure time_frame is passed here
          }),
        });
        const data = await response.json();
        if (data.token === 'valid') {
          const groupedData = data.recent_transactions.reduce((acc, transaction) => {
            const { location_id, group_id, charger_id } = transaction;

            if (!acc[location_id]) {
              acc[location_id] = { groups: {}, total: 0 };
            }
            acc[location_id].total += transaction.transaction_amount;

            if (!acc[location_id].groups[group_id]) {
              acc[location_id].groups[group_id] = { chargers: {}, total: 0 };
            }
            acc[location_id].groups[group_id].total += transaction.transaction_amount;

            if (!acc[location_id].groups[group_id].chargers[charger_id]) {
              acc[location_id].groups[group_id].chargers[charger_id] = { total: 0 };
            }
            acc[location_id].groups[group_id].chargers[charger_id].total += transaction.transaction_amount;

            return acc;
          }, {});

          setLocationGroups(groupedData);
        } else {
          alert('Session expired or invalid. Please log in again.');
        }
      } catch (error) {
        console.error('Error fetching charging sessions:', error);
        alert('An error occurred while fetching charging sessions.');
      }
    };

    if (token) {
      getChargingSessions(); // Fetch data whenever the time frame changes
    }
  }, [backendUrl, token, selectedTimeFrame]); // `selectedTimeFrame` is a dependency

  const handleClick = (type, id) => {
    if (type === 'location') {
      setExpandedLocation(expandedLocation === id ? null : id);
      setExpandedGroup(null);
    } else if (type === 'group') {
      setExpandedGroup(expandedGroup === id ? null : id);
    }
    fetchChartData(type, id);
  };

  const fetchChartData = async (type, id) => {
    try {
      const response = await fetch(`${backendUrl}/sessions/OwnerhistoryDetails`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_token: token, [`${type}_id`]: id ,
          time_frame: selectedTimeFrame,}),
      });
      const data = await response.json();
      if (data.token !== 'valid') {
        alert('Session expired or invalid. Please log in again.');
        return;
      }

      const labels = [];
      const transactionAmounts = [];
      let cumulativeAmounts = [];
      let cumulativeSum = 0;

      data.transaction_detail.forEach((detail) => {
        const date = new Date(detail.transaction_dt).toLocaleDateString();
        labels.push(date);
        transactionAmounts.push(detail.transaction_amount);
        cumulativeSum += detail.transaction_amount;
        cumulativeAmounts.push(cumulativeSum);
      });

      const cumulativeChartData = {
        labels: labels,
        datasets: [
          {
            label: 'Cumulative Earnings ($)',
            data: cumulativeAmounts,
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
          },
        ],
      };

      const transactionChartData = {
        labels: labels,
        datasets: [
          {
            label: 'Transaction Amount ($)',
            data: transactionAmounts,
            backgroundColor: 'rgba(153, 102, 255, 0.6)',
            borderColor: 'rgba(153, 102, 255, 1)',
            borderWidth: 1,
          },
        ],
      };

      setCumulativeData(cumulativeChartData);
      setTransactionLevelData(transactionChartData);
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  };

  const handleTimeFrameChange = (timeFrame) => {
    setSelectedTimeFrame(timeFrame);
  };

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <Container fluid style={{ marginLeft: '5px' }}>
        <Row className="justify-content-center my-5">
          <Col md={12}>
            <h2 className="mb-4 text-center">Charging History</h2>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Time Frame: {selectedTimeFrame}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleTimeFrameChange('ALL')}>ALL</Dropdown.Item>
                <Dropdown.Item onClick={() => handleTimeFrameChange('MONTH_TO_DATE')}>Month to Date</Dropdown.Item>
                <Dropdown.Item onClick={() => handleTimeFrameChange('YEAR_TO_DATE')}>Year to Date</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Row className="mt-4">
              <Col lg={4}>
                <ListGroup>
                  {Object.keys(locationGroups).map((location_id) => (
                    <div key={location_id}>
                      <ListGroup.Item
                        onClick={() => handleClick('location', location_id)}
                        action
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                      >
                        <div>
                          <div style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                            Location ID: {location_id}
                          </div>
                        </div>
                        <div style={{ color: 'green', fontWeight: 'bold' }}>
                          ${locationGroups[location_id].total.toFixed(2)}
                        </div>
                      </ListGroup.Item>

                      {expandedLocation === location_id &&
                        Object.keys(locationGroups[location_id].groups).map((group_id) => (
                          <ListGroup.Item
                            key={group_id}
                            onClick={() => handleClick('group', group_id)}
                            action
                            style={{ paddingLeft: '20px', fontSize: '0.9rem' }}
                          >
                            Group ID: {group_id} (${locationGroups[location_id].groups[group_id].total.toFixed(2)})
                          </ListGroup.Item>
                        ))}

                      {expandedLocation === location_id &&
                        expandedGroup &&
                        Object.keys(locationGroups[location_id].groups[expandedGroup].chargers).map((charger_id) => (
                          <ListGroup.Item
                            key={charger_id}
                            onClick={() => handleClick('charger', charger_id)}
                            action
                            style={{ paddingLeft: '40px', fontSize: '0.8rem' }}
                          >
                            Charger ID: {charger_id} (${locationGroups[location_id].groups[expandedGroup].chargers[charger_id].total.toFixed(2)})
                          </ListGroup.Item>
                        ))}
                    </div>
                  ))}
                </ListGroup>
              </Col>

              <Col lg={8}>
                {cumulativeData && (
                  <Card className="mb-4">
                    <Card.Body>
                      <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                        <h5>Cumulative Earnings</h5>
                      </div>
                      <Line data={cumulativeData} />
                    </Card.Body>
                  </Card>
                )}

                {transactionLevelData && (
                  <Card>
                    <Card.Body>
                      <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                        <h5>Transaction Level Earnings</h5>
                      </div>
                      <Bar data={transactionLevelData} />
                    </Card.Body>
                  </Card>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BusinessChargingHistory;
