import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import Sidebar from '../../../Components/Sidebar';
import { MapContainer, TileLayer, Marker, Popup,useMap,GeoJSON } from 'react-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import debounce from 'lodash.debounce';
import getEnvConfig from '../../../config';
import {  fetchToken, RequireToken,clearToken } from '../../../Auth';

const UserProfile = () => {

    const { backendUrl } = getEnvConfig();
    const token = fetchToken();
    console.log(token);
    const [userData, setUserData] = useState({
      address: '',
      email: '',
      phone: '',
    });
  
    const [editState, setEditState] = useState({
      address: false,
      email: false,
      phone: false,
    });
  
    const [verificationCodes, setVerificationCodes] = useState({
      email: '',
      phone: '',
    });
  
    const [verificationInputs, setVerificationInputs] = useState({
      emailCode: '',
      phoneCode: '',
    });
  
    useEffect(() => {
      // Fetch user data from the backend
      const fetchUserData = async () => {
          try {
              const response = await axios.post(`${backendUrl}/users/getUser`, { user_token: token });
              const user = response.data.user;
              setUserData({
                  address: `${user.address}, ${user.city}, ${user.state}, ${user.zipcode}`,
                  email: user.email,
                  phone: user.phone_number,
              });
              getAddressCoords(`${user.address}, ${user.city}, ${user.state}, ${user.zipcode}`);
          } catch (error) {
              console.error('Error fetching user data:', error);
          }
      };

      fetchUserData();
  }, [backendUrl, token]);
  
    const [addressCoords, setAddressCoords] = useState({ lat: 0, lng: 0 });
    const [verifiedAddress, setVerifiedAddress] = useState(false);
  
    const MapUpdateComponent = ({ lat, lng }) => {
        const map = useMap();
        useEffect(() => {
          if (lat && lng) {
            map.flyTo([lat, lng], 13);
          }
        }, [lat, lng, map]);
        return null;
      };

    useEffect(() => {
      // Fetch initial coordinates for the address
      getAddressCoords(userData.address);
    }, []);
  
    const getAddressCoords = async (address) => {
      try {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json`);
        const results = await response.json();
        if (results.length > 0) {
          const { lat, lon } = results[0];
          setAddressCoords({ lat: parseFloat(lat), lng: parseFloat(lon) });
     
        }
      } catch (error) {
        console.error('Error fetching address coordinates:', error);
      }
    };
  
    const debouncedGetAddressCoords = debounce((address) => {
      getAddressCoords(address);
    }, 1000);


  
    const handleEditClick = (field) => {
      setEditState({ ...editState, [field]: true });
  
      if (field === 'email') {
        // Send OTP to phone for email change
        axios.post(`${backendUrl}/send-phone-otp`, {
          token,
        }).then((response) => {
          // Handle response if needed
        }).catch((error) => {
          console.error('Error sending OTP to phone:', error);
        });
      } else if (field === 'phone') {
        // Send code to email for phone change
        axios.post(`${backendUrl}/send-email-code`, {
          token,
        }).then((response) => {
          // Handle response if needed
        }).catch((error) => {
          console.error('Error sending code to email:', error);
        });
      }
    };
  
    const handleVerificationChange = (e) => {
      const { name, value } = e.target;
      setVerificationInputs({ ...verificationInputs, [name]: value });
    };
  
    const handleVerificationSubmit = (field) => {
      const verificationCode = verificationInputs[`${field}Code`];
      
      axios.post(`${backendUrl}/verify-${field}-code`, {
        token,
        code: verificationCode,
      }).then((response) => {
        // Allow field editing if verification is successful
        if (response.data.verified) {
          setVerificationCodes({ ...verificationCodes, [field]: verificationCode });
          setEditState({ ...editState, [field]: true });
        } else {
          alert('Verification failed. Please try again.');
        }
      }).catch((error) => {
        console.error(`Error verifying ${field} code:`, error);
      });
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setUserData({ ...userData, [name]: value });
      if (name === 'address') {
        setVerifiedAddress(false);
      }
    };
  
    const handleSaveClick = async (field) => {
      if (field === 'address' && !verifiedAddress) {
        alert('Please verify the new address before saving.');
        return;
      }
      await verifyAndSaveAddress(field);
    };
  
    const handleVerifyAddressClick = async () => {
      await verifyAndSaveAddress('address', true);
    };
  
    const verifyAndSaveAddress = async (field, isVerify = false) => {
      if (field === 'address') {
        try {

            setAddressCoords({ lat: parseFloat(0), lng: parseFloat(0) });  
            await getAddressCoords(userData.address);

          if (addressCoords.lat === 0 && addressCoords.lng === 0) {
            alert('Invalid address. Please check the address and try again.');

            }
          setVerifiedAddress(true);
          if (!isVerify) {
            await axios.post(`${backendUrl}/update-${field}`, {
              token,
              [field]: userData[field],
            });
            setEditState({ ...editState, [field]: false });
            
          } else {
            
          }
        } catch (error) {
          alert('Address verification failed. Please check the address and try again.');
        }
      }
    };
  

    
    const customMarker = new L.Icon({
        iconUrl: "/images/Gps-blue.png",     
        iconcolor: 'red',
        iconSize: [20, 30],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40]
      });

  return (
    <div style={{ display: 'flex'}}>
      <Sidebar />
      <Container fluid style={{ marginLeft: '5px' }}>
        <Row className="justify-content-center my-5">
          <Col md={10}>
            <h2 className="mb-4 text-center">User Profile</h2>
            
            <Card className="mb-4">
              <Card.Body>
                <Row>
                  <Col md={4} className="d-flex align-items-center justify-content-center">
                
                      <MapContainer
                        center={[addressCoords.lat, addressCoords.lng]}
                        zoom={13}
                        style={{ height: '200px', width: '100%',borderRadius: '0px' }}
                        className="custom-map-container"
                      >
                        <TileLayer
                          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[addressCoords.lat, addressCoords.lng]} icon={customMarker}>
                          <Popup>Your Address</Popup>
                        </Marker>
                        <MapUpdateComponent lat={addressCoords.lat} lng={addressCoords.lng} />
                      </MapContainer>
        
                  </Col>
                  <Col md={8}>
                    <Card.Title>Address</Card.Title>
                    <Form>
                      <Form.Group controlId="formAddress">
                        <Form.Control
                          type="text"
                          name="address"
                          value={userData.address}
                          onChange={handleChange}
                          disabled={!editState.address}
                        />
                      </Form.Group>
                      {!editState.address && (
                        <Button variant="secondary" onClick={() => handleEditClick('address')} disabled>Edit</Button>
                      )}
                      {editState.address && (
                        <>
                          <Button variant="info" onClick={handleVerifyAddressClick} className="me-2" disabled>Verify Address</Button>
                          <Button variant="success" onClick={() => handleSaveClick('address')} disabled>Save</Button>
                        </>
                      )}
                    </Form>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <Row>
                  <Col md={4} className="d-flex align-items-center justify-content-center">
                    <img src="/images/email.png" alt="Email" style={{ borderRadius: '50%', width: '100px', height: '100px' }} />
                  </Col>
                  <Col md={8}>
                    <Card.Title>Email</Card.Title>
                    <Form>
                      <Form.Group controlId="formEmail">
                        <Form.Control
                          type="email"
                          name="email"
                          value={userData.email}
                          onChange={handleChange}
                          disabled={!editState.email}
                        />
                      </Form.Group>
                      {!editState.email && (
                        <Button variant="secondary" onClick={() => handleEditClick('email')} disabled>Edit</Button>
                      )}
                      {editState.email && (
                        <>
                          <Form.Group controlId="formEmailCode" className="mt-2">
                            <Form.Label>Enter code sent to your phone</Form.Label>
                            <Form.Control
                              type="text"
                              name="emailCode"
                              value={verificationInputs.emailCode}
                              onChange={handleVerificationChange}
                            />
                          </Form.Group>
                          <Button variant="success" onClick={() => handleVerificationSubmit('email')} disabled>Verify and Save</Button>
                        </>
                      )}
                    </Form>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <Row>
                  <Col md={4} className="d-flex align-items-center justify-content-center">
                    <img src="/images/phone.png" alt="Phone" style={{ borderRadius: '50%', width: '100px', height: '100px' }} />
                  </Col>
                  <Col md={8}>
                    <Card.Title>Phone</Card.Title>
                    <Form>
                      <Form.Group controlId="formPhone">
                        <Form.Control
                          type="text"
                          name="phone"
                          value={userData.phone}
                          onChange={handleChange}
                          disabled={!editState.phone}
                        />
                      </Form.Group>
                      {!editState.phone && (
                        <Button variant="secondary" onClick={() => handleEditClick('phone')} disabled>Edit</Button>
                      )}
                      {editState.phone && (
                        <>
                          <Form.Group controlId="formPhoneCode" className="mt-2">
                            <Form.Label>Enter code sent to your email</Form.Label>
                            <Form.Control
                              type="text"
                              name="phoneCode"
                              value={verificationInputs.phoneCode}
                              onChange={handleVerificationChange}
                            />
                          </Form.Group>
                          <Button variant="success" onClick={() => handleVerificationSubmit('phone')} disabled>Verify and Save</Button>
                        </>
                      )}
                    </Form>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserProfile;
