import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import Sidebar from '../../../../Components/Sidebar';
import getEnvConfig from '../../../../config';

const ChargerOptions = () => {
    const [chargerCosts, setChargerCosts] = useState([]);
    const { backendUrl } = getEnvConfig();

    useEffect(() => {
        const fetchChargerCosts = async () => {
            try {
                const response = await axios.get(`${backendUrl}/ecomm/get_charger_costs`);
                setChargerCosts(response.data);
            } catch (error) {
                console.error("Error fetching charger costs:", error);
            }
        };

        fetchChargerCosts();
    }, [backendUrl]);

    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <Container fluid style={{ marginLeft: '5px' }}>
                <Row className="justify-content-center my-5">
                    <Col md={10}>
                        <h2 className="mb-4 text-center">Available Charger Options</h2>
                        {chargerCosts.map((charger, index) => (
                            <Card key={index} className="mb-4" style={{ width: '100%' }}>
                                <Row noGutters>
                                    <Col md={4}>
                                        <Card.Img 
                                            variant="top" 
                                            src={charger.image_url} 
                                            alt={`${charger.charger_type} ${charger.mount_type}`} 
                                            style={{ width: '100%', height: 'auto', objectFit: 'contain', maxHeight: '300px' }} 
                                        />
                                    </Col>
                                    <Col md={8}>
                                        <Card.Body>
                                            <Card.Title>{charger.charger_name}</Card.Title>
                                            <Card.Text><strong>SKU:</strong> {charger.charger_sku}</Card.Text>
                                            <Card.Text><strong>Mount Type:</strong> {charger.mount_type.charAt(0).toUpperCase() + charger.mount_type.slice(1)}</Card.Text>
                                            <Card.Text><strong>Current Rating:</strong> {charger.current_rating_amps} Amps</Card.Text>
                                            <Card.Text><strong>Charging Rate:</strong> {charger.charging_rate_kwh} kWh</Card.Text>
                                            <Card.Text><strong>Total Cost:</strong> ${charger.total_cost.toFixed(2)}</Card.Text>
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Card>
                        ))}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ChargerOptions;
