import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import MainNavbar from '../../../../Components/Navbars/MainNavbar';
import Footer from '../../../../Components/Footer';
import getEnvConfig from "../../../../config";
import axios from 'axios';
import { setToken, clearToken, fetchToken, RequireToken } from "../../../../Auth";


const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { backendUrl } = getEnvConfig();
  const [rememberMe, setRememberMe] = useState(true);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [clientInfo, setClientInfo] = useState({
    browser: navigator.userAgent,  // Get the browser's user agent string
    ip: '',  // IP will be set later
    location: ''  // Location will be set later
  });

  console.log(backendUrl);

  // Function to get the user's IP address
  const fetchIP = async () => {
    try {
      const response = await axios.get('https://api64.ipify.org?format=json');
      setClientInfo(prevState => ({ ...prevState, ip: response.data.ip }));
      fetchLocation(response.data.ip);  // Fetch location based on IP
    } catch (error) {
      console.error('Error fetching IP:', error);
    }
  };

  // Function to get the user's location based on IP
  const fetchLocation = async (ip) => {
    try {
      // Use ipapi.co to get location data
      const locationResponse = await axios.get(`https://ipapi.co/${ip}/json/`);
      setClientInfo(prevState => ({
        ...prevState, 
        location: `${locationResponse.data.city}, ${locationResponse.data.country_name}`
      }));
    } catch (error) {
      console.error('Error fetching location:', error);
    }
  };

  useEffect(() => {
    fetchIP();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const data = {
      username: formData.username,
      password: formData.password,
      browser: clientInfo.browser,
      ip: clientInfo.ip,
      location: clientInfo.location
    };

    try {
      const response = await fetch(backendUrl + "/users/login", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
        
      });

      const responseData = await response.json();

      if (response.status === 200) {
        const data = responseData;
        if (data.access_token === 'Invalid User') {
          alert('Invalid User');
        } else {
          setToken({ token: data.access_token });
          const from = location.state?.from?.pathname || "/users/user-profile";
          navigate(from, { replace: true });
        }
      } else {
        // handle error
        alert('Error: ' + JSON.stringify(responseData));
      } 
    } catch (error) {
      console.error("Error:", error);
      alert('Error: ' + error.message);
    }
  };

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <Container fluid={true}>
      <MainNavbar />
      <Row className="justify-content-center my-5">
        <Col md={8} lg={6}>
          <Card className="p-4 shadow-sm">
            <h4 className="mb-3">Sign In</h4>
            <Form onSubmit={submitHandler}>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control 
                  type="email" 
                  placeholder="Enter your email" 
                  name="username" 
                  value={formData.username} 
                  onChange={handleChange} 
                  required 
                />
              </Form.Group>
              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control 
                  type="password" 
                  placeholder="Enter your password" 
                  name="password" 
                  value={formData.password} 
                  onChange={handleChange} 
                  required 
                />
              </Form.Group>
              <Form.Group controlId="formRememberMe" className="mb-3">
                <Form.Check 
                  type="checkbox" 
                  label="Remember me" 
                  checked={rememberMe} 
                  onChange={handleSetRememberMe} 
                />
              </Form.Group>
              <Button variant="primary" type="submit" block>
                Sign In
              </Button>
            </Form>
            <div className="mt-3 text-center">
              <span>Don't have an account? <Link to="/signup">Sign Up</Link></span>
              <br />
              <span><Link to="/reset-password">Forgot password?</Link></span>
            </div>
          </Card>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default SignIn;
