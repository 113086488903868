import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm'; // Adjust the path as per your project structure
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import MainNavbar from '../../../../Components/Navbars/MainNavbar';
import Footer from '../../../../Components/Footer';
import Sidebar from '../../../../Components/Sidebar';

const stripePromise = loadStripe('pk_test_51OLf6SB54WNp7HCzCZd26m82Dbx0eVaE2ItYP7eNu64UdbSrfXOxC1MmN5e4UsrvIIOfTJmxYu1moMCZGSqexYxo00BwOiwPLG');

const ChargerDetails= () => {
    return (

      <div style={{ display: 'flex' }}>
      <Sidebar />
      <Container fluid style={{ marginLeft: '5px' }}> {/* Adjust margin-left as needed */}
      <Row className="justify-content-center my-5">
          <Col md={8} lg={6}>
      
                <Elements stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
      
          </Col>
        </Row>
      </Container>
    </div>


    );
};

export default ChargerDetails;