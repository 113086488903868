import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Dropdown, Image } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChargingStation, faLeaf, faBolt, faCar, faDollar, faWind,faSmog } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../../../../Components/Sidebar';
import getEnvConfig from '../../../../config';
import { fetchToken } from '../../../../Auth';

const DriverDashboard = () => {
    const { backendUrl } = getEnvConfig();
    const [metrics, setMetrics] = useState({});
    const [tips, setTips] = useState([]);
    const [timeFrame, setTimeFrame] = useState('all');
    const token = fetchToken();

    const fetchMetrics = async () => {
        const response = await axios.post(`${backendUrl}/dashboard/metrics`, { time_frame: timeFrame, user_token: token });
        setMetrics(response.data);
    };

    const fetchTips = async () => {
        const response = await axios.get(`${backendUrl}/dashboard/tips`);
        setTips(response.data.tips);
    };

    useEffect(() => {
        fetchMetrics();
        fetchTips();
    }, [timeFrame]);

    const handleTimeFrameChange = (eventKey) => {
        setTimeFrame(eventKey);
    };

    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <Container fluid style={{ marginLeft: '5px' }}>
                <Row className="justify-content-center my-5">
                <Col md={12}>
                    <h2 className="dashboard-title">EV Driver Dashboard</h2>
                    <Col className="text-right">
                    <Dropdown onSelect={handleTimeFrameChange}>
                    <Dropdown.Toggle variant="success">
                                Time Frame: {timeFrame.toUpperCase()}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="month_to_date">Month to Date</Dropdown.Item>
                                <Dropdown.Item eventKey="year_to_date">Year to Date</Dropdown.Item>
                                <Dropdown.Item eventKey="all">All</Dropdown.Item>
                            </Dropdown.Menu>
                    </Dropdown>                   
                    </Col>
    

                </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <h5>Most Recently Used Charger: </h5>
                                <Button href={metrics.recent_charger}>Go to Charger</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <br></br>
                

                <Row>
                    <Col md={4}>
                        <Card className="stat-card">
                            <Card.Body className="text-center">
                                <FontAwesomeIcon icon={faChargingStation} size="3x" className="icon" />
                                <Card.Title>Total Charging Sessions</Card.Title>
                                <h2>{metrics.total_sessions} v/s {metrics.avg_sessions}</h2>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="stat-card">
                            <Card.Body className="text-center">
                                <FontAwesomeIcon icon={faBolt} size="3x" className="icon" />
                                <Card.Title>Total KWh Energy Used</Card.Title>
                                <h2>{metrics.total_kwh} kWh v/s {metrics.avg_kwh} </h2>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="stat-card">
                            <Card.Body className="text-center">
                                <FontAwesomeIcon icon={faCar} size="3x" className="icon" />
                                <Card.Title>Estimated Miles Driven</Card.Title>
                                <h2>{metrics.total_miles} miles v/s {metrics.avg_miles} </h2>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col md={4}>
                        <Card className="stat-card">
                            <Card.Body className="text-center">
                                <FontAwesomeIcon icon={faWind} size="3x" className="icon" />
                                <Card.Title>Total Carbon Saved</Card.Title>
                                <h2>{metrics.carbon_saved} kg v/s {metrics.avg_carbon_saved} </h2>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="stat-card">
                            <Card.Body className="text-center">
                                <FontAwesomeIcon icon={faSmog} size="3x" className="icon" />
                                <Card.Title>Total Pollutants Saved</Card.Title>
                                <h2>{metrics.pollutants_saved} kg v/s {metrics.avg_pollutants_saved}</h2>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="stat-card">
                            <Card.Body className="text-center">
                                <FontAwesomeIcon icon={faDollar} size="3x" className="icon" />
                                <Card.Title>Cost Savings vs Gas Vehicles</Card.Title>
                                <h2>${metrics.total_cost_savings} v/s {metrics.avg_total_cost_savings} </h2>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col md={12}>
                        <Card className="eco-card">
                            <Card.Body>
                                <Card.Title>Eco-Friendly Driving Tips</Card.Title>
                                <ul>
                                    {tips.map((tip, idx) => (
                                        <li key={idx}>{tip}</li>
                                    ))}
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>


            </Container>
        </div>
    );
};

export default DriverDashboard;
