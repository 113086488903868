import React from 'react';
import MainNavbar from '../../../Components/Navbars/MainNavbar';
import Footer from '../../../Components/Footer';
import { Container, Row, Col, CardGroup, Card } from 'react-bootstrap';

const WhyUsSolutions = () => {
  return (
    <Container fluid={true}>
      <MainNavbar />

      <Row>
        <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
          <span className="text-center">
            <h4>Why Choose EV Chron for Property and Parking Owners?</h4>
            <p>Discover the advantages of partnering with EV Chron for multi-family properties and parking management.</p>
          </span>
        </div>
      </Row>

      <Row>
        <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
          <CardGroup>
            <Card className="mx-1 align-items-center py-3 px-3">
              <Card.Body>
                <Card.Title>Lower Barriers to Entry</Card.Title>
                <Card.Text>
                  EV Chron offers property owners EV chargers at <strong>low prices</strong> with no hidden fees, making it easy and affordable to start. The only ongoing cost is a <strong>small communication fee</strong>. This ensures property owners can adopt EV charging infrastructure with minimal upfront investment.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mx-1 align-items-center py-3 px-3">
              <Card.Body>
                <Card.Title>Participation in the OpenADR System</Card.Title>
                <Card.Text>
                  Our platform supports the <strong>OpenADR system</strong>, enabling automatic adjustment to charging rates or pause charging during peak grid demand, helping to reduce costs and support grid stability. This flexibility allows property owners to contribute to energy conservation while maintaining control over their operations.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mx-1 align-items-center py-3 px-3">
              <Card.Body>
                <Card.Title>Smart Load Balancing</Card.Title>
                <Card.Text>
                  Our smart load-balancing technology allows multiple chargers to work on the same circuit without overloading, maximizing the efficiency and earning potential of your property. This ensures your chargers operate smoothly, even in high-demand environments, enhancing the value of your EV infrastructure.
                </Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </div>
      </Row>

      {/* Full Width OpenADR Section */}
      <Row>
        <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
          <Col md={12}>
            <Card className="p-3">
              <Card.Body className="d-flex align-items-center">
                <Card.Title className="mr-3">Participation in the OpenADR System</Card.Title>
                <img 
                  src="/images/openadrlogo347x75.webp" 
                  alt="OpenADR Logo" 
                  style={{ width: "300px", marginLeft: "10px", borderRadius: "0" }} 
                />
              </Card.Body>
              <Card.Text>
                Our platform supports the <strong>OpenADR system</strong>, enabling you to adjust charging rates or pause charging during peak grid demand, helping to reduce costs and support grid stability.
              </Card.Text>
            </Card>
          </Col>
        </div>
      </Row>


      <Row>
        <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
          <div className="row align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-6">
              <img src="/images/payment_processing.webp" className="d-block mx-lg-auto img-fluid" alt="Payment Processing"  style={{ maxWidth: "100%", maxHeight: "450px" }} height="200" loading="lazy" />
            </div>
            <div className="col-lg-6">
              <h3 className="display-6 fw-bold lh-4 mb-3">End-to-End Payment Processing</h3>
              <p className="lead">
                We handle all payment processing, tax calculations, and deposit the earnings directly into your account. This allows you to focus on managing your property while we ensure a seamless financial flow from your EV chargers. By handling the complexities, we help you maximize your revenue without added stress.
              </p>
            </div>
          </div>
        </div>
      </Row>

      <Row>
        <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
          <div className="row align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-6">
              <img src="/images/flexible_pricing.webp" className="d-block mx-lg-auto img-fluid" alt="Flexible Pricing" height="200" style={{ maxWidth: "100%", maxHeight: "450px" }} loading="lazy" />
            </div>
            <div className="col-lg-6">
              <h3 className="display-6 fw-bold lh-4 mb-3">Flexible Pricing</h3>
              <p className="lead">
                You have the flexibility to set and adjust the charging prices as you see fit, giving you control over your revenue generation strategies. Whether you want to incentivize usage during off-peak hours or adjust rates for special events, our platform gives you the tools to adapt and maximize earnings.
              </p>
            </div>
          </div>
        </div>
      </Row>

      <Row>
        <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
          <div className="row align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-6">
              <img src="/images/seamless_management.webp" className="d-block mx-lg-auto img-fluid" alt="Seamless Management" height="200" style={{ maxWidth: "100%", maxHeight: "450px" }} loading="lazy" />
            </div>
            <div className="col-lg-6">
              <h3 className="display-6 fw-bold lh-4 mb-3">Seamless Management</h3>
              <p className="lead">
                Our platform provides real-time data and reporting on charger usage, allowing you to make informed decisions and manage your EV chargers with ease. By analyzing data on usage patterns and revenue, you can optimize pricing, policies, and promotions to maximize your property's earnings potential.
              </p>
            </div>
          </div>
        </div>
      </Row>

      <Footer />
    </Container>
  );
};

export default WhyUsSolutions;
