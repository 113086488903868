import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import MainNavbar from '../../../Components/Navbars/MainNavbar';
import Footer from '../../../Components/Footer';

const AboutUs = () => {
  return (
    <Container fluid={true}>
      <MainNavbar />
      <Row className="justify-content-center my-5">
        <Col md={10}>
          <h2 className="mb-4 text-center">About Us</h2>
          
          <Row className="align-items-center g-5 py-5">
            <Col md={6}>
            <img src="/images/about_us_image.webp" className="d-block mx-lg-auto img-fluid" style={{ maxWidth: "100%", maxHeight: "450px" }} alt="Government Incentives and Rebates" loading="lazy"/>

            </Col>
            <Col md={6}>
              <div className="content">
                <h3 className="display-6 fw-bold lh-4 mb-3">Our Mission</h3>
                <p className="lead">
                  We are engineers and builders with a passion for sustainability and a commitment to addressing the challenges of climate change. Our journey began with a simple yet powerful idea: to create a business that not only helps combat climate change but does so in a practical, scalable way that benefits both property owners and electric vehicle (EV) drivers.
                </p>
                <p className="lead">
                  As professionals who have built and managed various projects, we understand the intricacies of running a business. We know that property and parking owners are often faced with tough decisions when it comes to upgrading infrastructure, especially when the return on investment isn’t clear. That’s why we’ve designed our solution to remove those barriers and make it easy for owners to integrate EV charging stations into their properties without the typical headaches.
                </p>
                <h3 className="display-6 fw-bold lh-4 mb-3">Empowering Property Owners</h3>
                <p className="lead">
                  We provide property and parking owners with cost-effective, reliable, and scalable EV charging solutions, ensuring they can capture new revenue streams and future-proof their properties as EV adoption grows. With no technical complexities and full control over pricing and management, property owners can now effortlessly integrate chargers into their existing infrastructure.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center g-5 py-5">
            <Col md={6}>
              <h3 className="display-6 fw-bold lh-4 mb-3">Enhancing the EV Driver Experience</h3>
              <p className="lead">
                For EV drivers, we’ve built a platform that ensures seamless and transparent access to charging. Our network allows drivers to find nearby charging stations, view real-time availability, and check pricing upfront. Whether at home, at work, or on the go, we make charging simple and stress-free.
              </p>
              <p className="lead">
                Through this dual-focus approach, we’re building a network that not only supports the growth of electric vehicles but makes it easier for everyone to contribute to a sustainable future while maximizing business potential.
              </p>
            </Col>
            <Col md={6}>
            <img src="/images/ev_driver_experience.webp" className="d-block mx-lg-auto img-fluid" style={{ maxWidth: "100%", maxHeight: "450px" }} alt="Government Incentives and Rebates" loading="lazy"/>

            </Col>
          </Row>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default AboutUs;
