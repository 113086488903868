import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
import getEnvConfig from "../../../../config";

// Removed unused imports and soft styling

function EmailVerification(props) {
  const [response, setResponse] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const { backendUrl } = getEnvConfig();

  // Get parameters from the URL
  const urlParams = new URLSearchParams(location.search);
  const code = urlParams.get('code');
  const email = urlParams.get('email');

  const openModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalContent('');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${backendUrl}/users/email/verify/${code}/${email}`, {
          method: "GET", 
        });

        const reply = await response.json();
        
        if (response.ok && reply['verification_check'] === 'approved') {
          // Redirect to phone verification with the retrieved phone number
          navigate('/authentication/phone_verification', { state: { phone: reply.phone } });
        } else {
          openModal('Error: ' + JSON.stringify(reply['verification_check']));
        }
      } catch (error) {
        openModal('Error occurred: ' + error.message);
      }
    };

    fetchData();
  }, [code, email, navigate]);

  return (
    <div className="email-verification-container">
      {response ? (
        <div>
          <h3>Email: {response.email}</h3>
          <p>Phone: {response.phone}</p>
        </div>
      ) : (
        <p>Verifying...</p>
      )}

      {/* Modal logic for error display */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h4>Error</h4>
            <p>{modalContent}</p>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default EmailVerification;
