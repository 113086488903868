import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup, Card } from 'react-bootstrap';
import { MapContainer, TileLayer, Marker, Popup, useMap, GeoJSON } from 'react-leaflet';
import Sidebar from '../../../../Components/Sidebar';

import getEnvConfig from '../../../../config';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const FindChargers = () => {

    const { backendUrl } = getEnvConfig();
    //const [chargerLoc, setChargerLoc] = useState(null);
  
  
    //const [chargerLoc, setChargerLoc] = useState(null);
    
  
    const greenMarker = new L.Icon({
      iconUrl: "/images/Gps-green.png",        
      iconcolor: 'red',
      iconSize: [20, 30],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40]
    });
  
  
    const redMarker = new L.Icon({
      iconUrl: "/images/Gps-red.png",        
      iconcolor: 'red',
      iconSize: [20, 30],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40]
    });
  
    function LocationMarker() {
      const [position, setPosition] = useState(null);
      
  
      const map = useMap();
  
      const customMarker = new L.Icon({
          iconUrl: "/images/Gps-blue.png",     
          iconcolor: 'red',
          iconSize: [20, 30],
          iconAnchor: [10, 41],
          popupAnchor: [2, -40]
        });
  
      useEffect(() => {
        /*alert('I am here : Locate')*/
        map.locate().on("locationfound", function (e) {
          setPosition(e.latlng);
          map.flyTo(e.latlng, map.getZoom());
        });
      }, [map]);
  
      return position === null ? null : (
        <Marker position={position} icon={customMarker}>
          <Popup>You are here</Popup>
        </Marker>
      );
    };
  
  
    // This function binds a popup to each feature
    function onEachFeature(feature, layer) {
      if (feature.properties && feature.properties.location_id) {
        // Assuming you want to create a link to a URL that includes the location_id
        alert(feature.properties.location_id)
        /*const url = `/charger?chargerid=${feature.properties.location_id}`;*/
        const url = `/charger/${feature.properties.location_id}`;
        layer.bindPopup(`<a href="${url}" ${feature.properties.location_id}</a>`);
      }
    }
  
    function ChargerLocationMarker() {
      const [chargerLoc, setChargerLoc] = useState(null);
  
      const map = useMap();
  
      
      useEffect(() => {
        map.locate().on("locationfound", function (e) {
          const { lat, lng } = e.latlng;
          const fetchData = async () => {
            try {
                const response = await fetch(backendUrl+`/chargers/listings/${lng},${lat}`, {
                    method: "GET", 
                });
                const reply = await response.json();        
         
                if (response.ok) {     
                  setChargerLoc  (reply['chargers']);
                  console.log(reply);    
                } else {
                   
                }
            } catch (error) {
                /*alert('I am here :'+error);*/
                
            }
        };  
        
        fetchData();
        
  
        });
      }, [map]);
  
  
  
      
  
  
      return chargerLoc === null ? null : (
        <GeoJSON data={chargerLoc} 
        pointToLayer={(feature, latlng) => {
          const marker = L.marker(latlng, { icon: greenMarker });
          console.log(feature.properties.charger_id)
      
          // Bind a popup to the marker
          const popupContent = feature.properties.charger_id
            ? `<a href="/charger/${feature.properties.charger_id}">charger_id: ${feature.properties.charger_id}</a>`
            : 'No charger id';
          marker.bindPopup(popupContent);
          
          return marker;
  
  
          //return L.marker(latlng, { icon: customMarker });
        }}
        onEachFeature={onEachFeature}
        />
    // render react-leaflet GeoJSON when the data is ready
  
  
    );
  };
  
  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <Container fluid style={{ marginLeft: '5px' }}>
       
      <Row>
        <Col className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
        
          <span className="text-center">
            <h4>Search for Charging Stations</h4>
            <p>Find and book the nearest EV charging stations available in your area.</p>
          </span>
        </Col>
      </Row>
      <Row>
        <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center" style={{ height: '600px', width: '100%' }}>
          <MapContainer
            center={[37, -95.7]}
            zoom={9}
            maxZoom={19}
            scrollWheelZoom
            style={{ height: "600px", width: "100%" }}
            className="custom-map-container"
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarker />
            <ChargerLocationMarker />
          </MapContainer>
        </div>
      </Row>

    </Container>
    </div>
  );

};

export default FindChargers;
