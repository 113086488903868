import { useLocation, Navigate, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import getEnvConfig from "./config";

const { backendUrl } = getEnvConfig();

const VALIDATION_INTERVAL = 5.4e+6; // 5 minutes in milliseconds
const ttl = 90000000; // 15 minutes in milliseconds

export function validateTokenPeriodically () {
    const intervalId = setInterval(async () => {
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          const response = await fetch(backendUrl+'/users/validate_token', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              access_token: token,
              token_type: 'bearer'
            })
          });
  
          const data = await response.json();
          if (data.token !== 'valid') {
            // Token is invalid or expired
            clearToken();
            clearInterval(intervalId); // Stop the interval
            RequireToken();
          }
        } catch (error) {
          console.error('Error during token validation:', error);
        }
      } else {
        //clearInterval(intervalId); // No token, stop the interval
        clearToken();
        clearInterval(intervalId); // Stop the interval        
        RequireToken();
      }
    }, VALIDATION_INTERVAL);
  }

export function setToken({ token }) {

    validateTokenPeriodically();
    const now = new Date();
    const item = {
      value: token,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem('access_token', JSON.stringify(item));
    return true;

}

export function clearToken (){
    localStorage.removeItem('access_token')

}



export function fetchToken(){
  try{

    const itemStr = localStorage.getItem('access_token')
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
      localStorage.removeItem('access_token')
      return null
    }
    return item.value
  } catch (error) {
    return null
  }

    
}

export const isUserLoggedIn = () => {
    return !!fetchToken();  // This will return true if there is a token, and false if there isn't.
}

export function RequireToken({children}){

    let auth = fetchToken()
    let location = useLocation()

    if(!auth){

        return <Navigate to='/signin' state ={{from : location}}/>;
    }

    return children;
}

RequireToken.propTypes = {
    children: PropTypes.node.isRequired, // Use 'node' for any valid React node
  };