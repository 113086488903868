import React, { createContext, useContext, useState, useEffect } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
    const [mode, setMode] = useState('driver');
  
    useEffect(() => {
      const storedMode = localStorage.getItem('sidebarMode');
      if (storedMode) {
        setMode(storedMode);
      }
      
    }, []);
  
    const toggleMode = () => {
      const newMode = mode === 'driver' ? 'propertyManager' : 'driver';
      setMode(newMode);
      localStorage.setItem('sidebarMode', newMode);
      
    };
  
    return (
      <SidebarContext.Provider value={{ mode, toggleMode }}>
        {children}
      </SidebarContext.Provider>
    );
  };
  

export const useSidebar = () => useContext(SidebarContext);
