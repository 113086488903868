import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup, Card, Button } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import Sidebar from '../../../../Components/Sidebar';
import getEnvConfig from '../../../../config';
import { fetchToken, RequireToken } from '../../../../Auth';
import { Chart, registerables } from 'chart.js';

// Register all necessary Chart.js components
Chart.register(...registerables);

const DriverChargingHistory = () => {
    const [transactions, setTransactions] = useState([]);
    const [selectedSession, setSelectedSession] = useState(null);
    const [usageData, setUsageData] = useState(null);
    const [selectedSessionDate, setSelectedSessionDate] = useState('');
    const { backendUrl } = getEnvConfig();
    const token = fetchToken();
  
    const [currentPage, setCurrentPage] = useState(1);
    const transactionsPerPage = 3;

    useEffect(() => {
      const getChargingSessions = async () => {
        try {
          const response = await fetch(`${backendUrl}/sessions/history`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              user_token: token,
            })
          });
          const data = await response.json();
          if (data.token === 'valid') {
            setTransactions(data.recent_transactions);
          } else {
            //clearToken();
          }
        } catch (error) {
          alert(error);
        }
      };

      getChargingSessions();
    }, [backendUrl, token]);

    const handleSessionClick = async (transaction_id, transaction_date) => {
      try {
        const response = await fetch(`${backendUrl}/sessions/session_details`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            trx_id: transaction_id,
          })
        });
        const data = await response.json();
        const labels = [];
        const kwh = [];
        data.transaction_detail.forEach(detail => {
          const time = new Date(detail.timestamp).toLocaleTimeString();
          labels.push(time);
          kwh.push(detail.usage_kwh);
        });

        const chargingLineChartData = {
          labels: labels,
          datasets: [
            {
              label: 'Usage (KWh)',
              data: kwh,
              borderColor: 'rgba(75, 192, 192, 1)',
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
            }
          ],
        };
        setUsageData(chargingLineChartData);
        setSelectedSession(transaction_id);
        setSelectedSessionDate(transaction_date);
      } catch (error) {
        console.error('Error fetching session details:', error);
      }
    };

    const paginate = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const indexOfLastTransaction = currentPage * transactionsPerPage;
    const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
    const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

    const nextPage = () => {
      if (currentPage < Math.ceil(transactions.length / transactionsPerPage)) {
        setCurrentPage(currentPage + 1);
      }
    };

    const previousPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };

    if (!token) {
      return <RequireToken />;
    }

    return (
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <Container fluid style={{ marginLeft: '5px' }}>
          <Row className="justify-content-center my-5">
            <Col md={12}>
              <h2 className="mb-4 text-center">Charging History</h2>
              <Row>
                <Col lg={4}>
                  <ListGroup>
                    {currentTransactions.map(transaction => (
                      <ListGroup.Item
                        key={transaction.transaction_id}
                        onClick={() => handleSessionClick(transaction.transaction_id, transaction.transaction_dt)}
                        action
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                      >
                        <div>
                          <div style={{ fontSize: '1rem', fontWeight: 'bold' }}>{transaction.city}, {transaction.state}</div>
                          <div style={{ fontSize: '0.8rem', color: 'grey' }}>{new Date(transaction.transaction_dt).toLocaleString()}</div>
                        </div>
                        <div style={{ color: 'green', fontWeight: 'bold' }}>${transaction.transaction_amount.toFixed(2)}</div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>

                  <div className="mt-3 d-flex justify-content-between">
                    <Button variant="secondary" onClick={previousPage} disabled={currentPage === 1}>
                      Previous
                    </Button>
                    <Button variant="secondary" onClick={nextPage} disabled={currentPage === Math.ceil(transactions.length / transactionsPerPage)}>
                      Next
                    </Button>
                  </div>
                </Col>
                <Col lg={8}>
                  {usageData && (
                    <Card>
                      <Card.Body>
                        <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                          <h5>{selectedSessionDate}</h5>
                        </div>
                        <Line data={usageData} />
                      </Card.Body>
                    </Card>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
};

export default DriverChargingHistory;
