import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import MainNavbar from '../../../Components/Navbars/MainNavbar';
import Footer from '../../../Components/Footer';


const ResourcesGuidances = () => {
  return (
    <Container fluid={true}>
      <MainNavbar />
      <Row className="justify-content-center my-5">
        <Col md={10}>
          <h2 className="mb-4 text-center">Resources & Guidance for EV Drivers</h2>
          
          <Row className="mb-4">
            <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
              <div className="row flex-lg-row-reverse align-items-center g-5 py-5">

                <div >
                  <h3 className="display-6 fw-bold lh-4 mb-3">Introduction</h3>
                  <p className="lead">Welcome to the Resources & Guidance section for EV Drivers. Whether you are new to electric vehicles or an experienced EV driver, this section is designed to provide you with valuable information, tips, and resources to enhance your driving experience and help you make the most of your EV.</p>
                </div>
              </div>
            </div>
          </Row>

          <Row className="mb-4">
            <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
              <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                <div className="col-10 col-sm-8 col-lg-6">
                  <img src="/images/ev_driver_benefits.webp" className="d-block mx-lg-auto img-fluid" style={{ maxWidth: "100%", maxHeight: "450px" }} alt="Benefits of Driving an EV" width="700" height="500" loading="lazy"/>
                </div>
                <div className="col-lg-6">
                  <h3 className="display-6 fw-bold lh-4 mb-3">Benefits of Driving an Electric Vehicle</h3>
                  <p className="lead">
                    <ul>
                      <li><strong>Environmental Impact:</strong> EVs produce zero tailpipe emissions, which significantly reduces air pollution and greenhouse gases.</li>
                      <li><strong>Cost Savings:</strong> EVs are cheaper to operate and maintain. You can save on fuel costs, and EVs have fewer moving parts, which means lower maintenance costs.</li>
                      <li><strong>Performance:</strong> EVs offer instant torque, providing a smooth and responsive driving experience.</li>
                      <li><strong>Convenience:</strong> Charging your EV at home or at work can be more convenient than stopping at a gas station.</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </Row>

          <Row className="mb-4">
            <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
              <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
              <div className="col-10 col-sm-8 col-lg-6">
                  <img src="/images/charging_network.webp" className="d-block mx-lg-auto img-fluid" style={{ maxWidth: "100%", maxHeight: "450px" }} alt="Charging Your EV and Maximizing Your EV’s Range" width="700" height="500" loading="lazy"/>
                </div>
                <div className="col-lg-6">
                  <h3 className="display-6 fw-bold lh-4 mb-3">Charging & Maximizing Your EV’s Range</h3>
                  <p className="lead">
                    One of the main aspects of owning an EV is understanding the different charging options available. Here’s a quick guide to help you get started:
                    <h5>Types of Chargers</h5>
                    <ul>
                      <li><strong>Level 1 Charging:</strong> Uses a standard 120V household outlet. It's the slowest charging option but can be sufficient for overnight charging.</li>
                      <li><strong>Level 2 Charging:</strong> Uses a 240V outlet, similar to those used for large appliances. It provides faster charging and is ideal for home or workplace charging.</li>
                      <li><strong>DC Fast Charging:</strong> Provides the fastest charging available and is typically found at public charging stations. It can charge an EV battery to 80% in around 30 minutes. However it does impact the battery capacity over the long term.</li>
                    </ul>
                    <h5>Maximizing Your EV’s Range</h5>
                    <ul>
                      <li><strong>Drive Efficiently:</strong> Smooth acceleration and braking can help extend your range.</li>
                      <li><strong>Climate Control:</strong> Use climate control settings wisely. Precondition your car while it’s still plugged in to save battery power.</li>
                      <li><strong>Maintenance:</strong> Keep your EV well-maintained. Proper tire pressure and regular service can help improve efficiency.</li>
                    </ul>
                  </p>
                </div>

              </div>
            </div>
          </Row>

          <Row className="mb-4">
            <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
              <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                <div className="col-10 col-sm-8 col-lg-6">
                  <img src="/images/routeplanning2.webp" className="d-block mx-lg-auto img-fluid" style={{ maxWidth: "100%", maxHeight: "450px" }} alt="Finding Charging Stations and Planning Your Trips" />
                </div>
                <div className="col-lg-6">
                  <h3 className="display-6 fw-bold lh-4 mb-3">Finding Charging Stations and Planning Your Trips</h3>
                  <p className="lead">
                    Planning trips with an EV requires a bit more thought compared to traditional vehicles. Here are some tips to ensure a smooth journey:
                    <ul>
                      <li><strong>Route Planning:</strong> Use our route planning tool to find the best routes with available charging stations. <a href="#plan-your-route">Plan Your Route</a>.</li>
                      <li><strong>Stay Updated:</strong> Check the status of charging stations along your route before you start your trip to avoid any surprises.</li>
                      <li><strong>Charging Stops:</strong> Plan your charging stops and ensure you have enough charge to reach your destination.</li>
                    </ul>
                    <h5>Finding Charging Stations</h5>
                    <ul>
                      <li><strong>EV Charging Network:</strong> Our extensive network of charging stations ensures that you can find a charger wherever you go. Use our <a href="#charging-station-locator">Charging Station Locator</a> to find the nearest station.</li>
                      <li><strong>Mobile App:</strong> Download our mobile app for real-time information on charger availability, status, and more. <a href="#download-app">Download Now</a>.</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </Row>

          <Row className="mb-4">
            <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
              <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
              <div className="col-10 col-sm-8 col-lg-6">
                  <img src="/images/stateev_incentives2.webp" className="d-block mx-lg-auto img-fluid" style={{ maxWidth: "100%", maxHeight: "450px" }} alt="Government Incentives and Rebates" loading="lazy"/>
                </div>   
                <div className="col-lg-6">
                  <h3 className="display-6 fw-bold lh-4 mb-3">Government Incentives and Rebates</h3>
                  <p className="lead">
                    Many governments offer incentives and rebates to encourage the adoption of electric vehicles. Here’s how you can benefit:
                    <ul>
                      <li><strong>Federal Tax Credits:</strong> Check if you qualify for federal tax credits when purchasing an EV. <a href="#learn-more">Learn More</a>.</li>
                      <li><strong>State and Local Incentives:</strong> Explore state and local incentives, which may include rebates, reduced registration fees, and more. <a href="#find-incentives">Find Incentives</a>.</li>
                      <li><strong>Charging Equipment Rebates:</strong> Some programs offer rebates for installing home charging equipment. <a href="#see-if-you-qualify">See If You Qualify</a>.</li>
                    </ul>
                  </p>
                </div>
             
              </div>
            </div>
          </Row>

          <Row className="mb-4">
            <div className="container col-xxl-8 px-3 py-3 d-flex align-items-center justify-content-center">
              <div className="row flex-lg-row-reverse align-items-center g-5 py-5">

                <div >
                  <h3 className="display-6 fw-bold lh-4 mb-3">Community and Support</h3>
                  <p className="lead">
                    Join the EV community and connect with other drivers:
                    <ul>
                      <li><strong>Forums and Groups:</strong> Participate in online forums and local EV groups to share experiences and get advice.</li>
                      <li><strong>Customer Support:</strong> Our dedicated support team is here to help you with any questions or issues. <a href="#contact-us">Contact Us</a>.</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </Row>

        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default ResourcesGuidances;
