import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form, Collapse } from 'react-bootstrap';
import axios from 'axios';
import Sidebar from '../../../../Components/Sidebar';
import getEnvConfig from '../.././../../config';
import { fetchToken } from '../../../../Auth';

const ManageGroupsAndChargers = () => {
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [chargers, setChargers] = useState([]);
    const [editingGroup, setEditingGroup] = useState(null);
    const [username, setUsername] = useState('');
    const [usernameSubmitted, setUsernameSubmitted] = useState(false);
    const token = fetchToken();
    const { backendUrl } = getEnvConfig();

    const handleUsernameSubmit = async (e) => {
        e.preventDefault();
        setUsernameSubmitted(true);
        await fetchLocations(username);
    };

    const fetchLocations = async (username) => {
        const response = await axios.post(`${backendUrl}/chargers/get_locations`, { user_token: token, username });
        setLocations(response.data.locations || []);
    };

    const handleLocationChange = async (e) => {
        const location_id = e.target.value;
        setSelectedLocation(location_id);
        const response = await axios.post(`${backendUrl}/chargers/get_groups`, { location_id, user_token: token, username });
        setGroups(response.data.groups || []);
    };

    const handleGroupChange = async (group) => {
        setSelectedGroup(group);
        const response = await axios.post(`${backendUrl}/chargers/get_chargers`, { group_id: group.id, user_token: token, username });
        setChargers(response.data.chargers || []);
    };

    const handleGroupUpdate = async (group) => {
        try {
            await axios.post(`${backendUrl}/chargers/update_group`, {
                ...group,
                user_token: token,
                username,
            });
            alert('Group updated successfully');
        } catch (error) {
            console.error('Error updating group:', error);
            alert('Error updating group');
        }
    };

    const handleChargerUpdate = async (charger) => {
        try {
            await axios.post(`${backendUrl}/chargers/update_charger`, {
                ...charger,
                user_token: token,
                username,
            });
            alert('Charger updated successfully');
        } catch (error) {
            console.error('Error updating charger:', error);
            alert('Error updating charger');
        }
    };

    const handleSaveGroup = (group) => {
        handleGroupUpdate(group);
        setEditingGroup(null); // Close editing mode after saving
    };

    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <Container fluid style={{ marginLeft: '5px' }}>
                <Row className="justify-content-center my-5">
                    <Col md={10}>
                        <h2 className="mb-4 text-center">Manage Charger Groups & Chargers</h2>
                        {!usernameSubmitted ? (
                            <Form onSubmit={handleUsernameSubmit}>
                                <Form.Group controlId="username" className="mb-3">
                                    <Form.Label>Enter User's Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter user's email"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="w-100">
                                    Fetch Locations
                                </Button>
                            </Form>
                        ) : (
                            <>
                                <Form.Group controlId="locationSelect" className="mb-4">
                                    <Form.Label>Select Location</Form.Label>
                                    <Form.Control as="select" onChange={handleLocationChange}>
                                        <option>Select a location...</option>
                                        {locations.map((location) => (
                                            <option key={location.id} value={location.id}>
                                                {location.address}, {location.city}, {location.state}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                {groups.map((group) => (
                                    <Card key={group.id} className="mb-3">
                                        <Card.Body>
                                            <Card.Title>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Control
                                                            type="text"
                                                            value={group.group_name || ''}
                                                            onChange={(e) =>
                                                                setGroups(
                                                                    groups.map((g) =>
                                                                        g.id === group.id
                                                                            ? { ...g, group_name: e.target.value }
                                                                            : g
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                    <Col md={6} className="text-right">
                                                        <Button
                                                            variant="secondary"
                                                            onClick={() =>
                                                                setEditingGroup(editingGroup === group.id ? null : group.id)
                                                            }
                                                        >
                                                            {editingGroup === group.id ? 'Save' : 'Edit Group'}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Card.Title>
                                            <Card.Text>
                                                Group ID: <strong>{group.id}</strong>
                                            </Card.Text>
                                            <Card.Text>
                                                Max Amps:
                                                <Form.Control
                                                    type="number"
                                                    value={group.total_max_amp || ''}
                                                    onChange={(e) =>
                                                        setGroups(
                                                            groups.map((g) =>
                                                                g.id === group.id
                                                                    ? { ...g, total_max_amp: e.target.value }
                                                                    : g
                                                            )
                                                        )
                                                    }
                                                />
                                            </Card.Text>
                                            <Card.Text>
                                                Utility ID:
                                                <Form.Control
                                                    type="text"
                                                    value={group.utility_id || ''}
                                                    onChange={(e) =>
                                                        setGroups(
                                                            groups.map((g) =>
                                                                g.id === group.id ? { ...g, utility_id: e.target.value } : g
                                                            )
                                                        )
                                                    }
                                                />
                                            </Card.Text>
                                            <Button variant="primary" onClick={() => handleSaveGroup(group)}>
                                                Save Group
                                            </Button>

                                            <Button variant="link" onClick={() => handleGroupChange(group)}>
                                                View Chargers in this Group
                                            </Button>

                                            <Collapse in={selectedGroup === group}>
                                                <div>
                                                    {chargers.map((charger) => (
                                                        <Card key={charger.id} className="mb-2">
                                                            <Card.Body>
                                                                <Form.Group controlId={`charger_${charger.id}_sim_iccid`}>
                                                                    <Form.Label>SIM ICCID</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={charger.sim_iccid || ''}
                                                                        onChange={(e) =>
                                                                            setChargers(
                                                                                chargers.map((c) =>
                                                                                    c.id === charger.id
                                                                                        ? { ...c, sim_iccid: e.target.value }
                                                                                        : c
                                                                                )
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Group>

                                                                <Form.Group controlId={`charger_${charger.id}_price_per_kwh`}>
                                                                    <Form.Label>Price per kWh</Form.Label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        value={charger.price_per_kwh || ''}
                                                                        onChange={(e) =>
                                                                            setChargers(
                                                                                chargers.map((c) =>
                                                                                    c.id === charger.id
                                                                                        ? { ...c, price_per_kwh: e.target.value }
                                                                                        : c
                                                                                )
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group controlId={`charger_${charger.id}_charging_rate`}>
                                                                    <Form.Label>Charging Rate</Form.Label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        value={charger.charging_rate || ''}
                                                                        onChange={(e) =>
                                                                            setChargers(
                                                                                chargers.map((c) =>
                                                                                    c.id === charger.id
                                                                                        ? { ...c, charging_rate: e.target.value }
                                                                                        : c
                                                                                )
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group controlId={`charger_${charger.id}_charger_amps`}>
                                                                    <Form.Label>Charging Amps</Form.Label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        value={charger.charger_amps || ''}
                                                                        onChange={(e) =>
                                                                            setChargers(
                                                                                chargers.map((c) =>
                                                                                    c.id === charger.id
                                                                                        ? { ...c, charger_amps: e.target.value }
                                                                                        : c
                                                                                )
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group controlId={`charger_${charger.id}_group_id`}>
                                                                    <Form.Label>Group ID</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={charger.group_id || ''}
                                                                        readOnly
                                                                    />
                                                                </Form.Group>
                                                                <Card.Text>
                                                                    UUID:{charger.uuid}
                                                                </Card.Text>
                                                                <Card.Text>
                                                                    ID:{charger.id}
                                                                </Card.Text>                                                                
                                                                <Card.Text>
                                                                    QR Code:{' '}
                                                                    {charger.qr_code && (
                                                                        <img
                                                                            src={`data:image/png;base64,${charger.qr_code}`}
                                                                            alt="QR Code"
                                                                        />
                                                                    )}
                                                                </Card.Text>

                                                                <Button variant="primary" onClick={() => handleChargerUpdate(charger)}>
                                                                    Save Charger Details
                                                                </Button>
                                                            </Card.Body>
                                                        </Card>
                                                    ))}
                                                </div>
                                            </Collapse>
                                        </Card.Body>
                                    </Card>
                                ))}
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ManageGroupsAndChargers;
