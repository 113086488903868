import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Form, Divider } from 'react-bootstrap';

import getEnvConfig from "../../../../config";

function ResetPassword() {
  const { backendUrl } = getEnvConfig();
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    // POST request to the backend
    const response = await fetch(backendUrl + '/users/password-reset/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });
    const data = await response.json();
    console.log(data);
    // Handle response
  };

  return (

      <Container fluid className="mt-5">
        <Row className="justify-content-center">
          <Col md={6}>
            <Card>
              <Card.Body>
                <Card.Title as="h3">Reset Password</Card.Title>
                <p>You will receive an e-mail within 60 seconds.</p>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <hr />
                  <Button type="submit" variant="dark">
                    Request Password Reset
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

  );
}

export default ResetPassword;
