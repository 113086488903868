import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Dropdown, Table } from 'react-bootstrap';
import axios from 'axios';
import Sidebar from '../../../../Components/Sidebar';
import getEnvConfig from '../../../../config';
import { fetchToken } from '../../../../Auth';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';

const PropertyManagerDashboard = () => {
    const { backendUrl } = getEnvConfig();
    const [propertyMetrics, setPropertyMetrics] = useState([]);
    const [timeFrame, setTimeFrame] = useState('all');
    const [averages, setAverages] = useState({
        totalRevenue: 0,
        totalSessions: 0,
        distinctUsers: 0,
        avgKwh: 0,
        avgIdleTime: 0,
    });
    const [distinctLocations, setDistinctLocations] = useState([]);
    const [distinctGroups, setDistinctGroups] = useState([]);
    const [distinctChargers, setDistinctChargers] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedCharger, setSelectedCharger] = useState(null);
    const token = fetchToken();

    const fetchPropertyMetrics = async () => {
        try {
            const response = await axios.post(`${backendUrl}/dashboard/property_manager_metrics`, { time_frame: timeFrame, user_token: token });
            const data = response.data.property_metrics;
            setPropertyMetrics(data);
            calculateAverages(data);

            // Extract distinct location, group, and charger IDs for filtering
            const locations = [...new Set(data.map(item => item.location_id))];
            const groups = [...new Set(data.map(item => item.group_id))];
            const chargers = [...new Set(data.map(item => item.charger_id))];

            setDistinctLocations(locations);
            setDistinctGroups(groups);
            setDistinctChargers(chargers);
        } catch (error) {
            console.error('Error fetching property metrics', error);
        }
    };

    const calculateAverages = (data) => {
        if (!data.length) return;

        let totalRevenue = 0;
        let totalSessions = data.length;
        let distinctUsers = new Set();
        let totalKwh = 0;
        let totalIdleTime = 0;

        data.forEach(item => {
            totalRevenue += parseFloat(item.base_amount);
            distinctUsers.add(item.user_id);
            totalKwh += parseFloat(item.kwh);
            const idleTime = (new Date(item.session_end_time) - new Date(item.charge_end_time)) / (1000 * 60 * 60); // Idle time in hours
            totalIdleTime += idleTime;
        });

        const avgKwh = totalKwh / data.length;
        const avgIdleTime = totalIdleTime / data.length;

        setAverages({
            totalRevenue: totalRevenue.toFixed(2),
            totalSessions,
            distinctUsers: distinctUsers.size,
            avgKwh: avgKwh.toFixed(2),
            avgIdleTime: avgIdleTime.toFixed(2),
        });
    };

    const handleFilterChange = () => {
        let filteredData = propertyMetrics;
    
        // Apply the location filter
        if (selectedLocation) {
            filteredData = filteredData.filter(item => item.location_id === parseInt(selectedLocation, 10));
        }
    
        // Apply the group filter
        if (selectedGroup) {
            filteredData = filteredData.filter(item => item.group_id === parseInt(selectedGroup, 10));
        }
    
        // Apply the charger filter
        if (selectedCharger) {
            filteredData = filteredData.filter(item => item.charger_id === parseInt(selectedCharger, 10));
        }
    
        // Recalculate averages and counts based on filtered data
        if (filteredData.length > 0) {
            calculateAverages(filteredData);
        } else {
            // Reset averages if no data matches the filter
            setAverages({
                avgRevenue: 0,
                avgSessions: 0,
                distinctUsers: 0,
                avgSessionLength: 0,
                avgIdleLength: 0,
                avgKwh: 0,
            });
        }
    };
    

    useEffect(() => {
        fetchPropertyMetrics();
    }, [timeFrame]);

    useEffect(() => {
        handleFilterChange();
    }, [selectedLocation, selectedGroup, selectedCharger]);

    const handleTimeFrameChange = (eventKey) => {
        setTimeFrame(eventKey);
    };

    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <Container fluid>
                <Row className="my-4">
                    <Col>
                        <h2>Property Manager Dashboard</h2>
                    </Col>
                    <Col className="text-right">
                        <Dropdown onSelect={handleTimeFrameChange}>
                            <Dropdown.Toggle variant="success">
                                Time Frame: {timeFrame.toUpperCase()}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="month_to_date">Month to Date</Dropdown.Item>
                                <Dropdown.Item eventKey="year_to_date">Year to Date</Dropdown.Item>
                                <Dropdown.Item eventKey="all">All</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>

                {/* Filters Section */}
                <Row className="mb-4">
                    <Col>
                        <Dropdown onSelect={(e) => setSelectedLocation(e)}>
                            <Dropdown.Toggle variant="secondary">
                                Location: {selectedLocation || 'All'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey={null}>All</Dropdown.Item>
                                {distinctLocations.map(loc => (
                                    <Dropdown.Item key={loc} eventKey={loc}>{loc}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col>
                        <Dropdown onSelect={(e) => setSelectedGroup(e)}>
                            <Dropdown.Toggle variant="secondary">
                                Group: {selectedGroup || 'All'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey={null}>All</Dropdown.Item>
                                {distinctGroups.map(group => (
                                    <Dropdown.Item key={group} eventKey={group}>{group}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col>
                        <Dropdown onSelect={(e) => setSelectedCharger(e)}>
                            <Dropdown.Toggle variant="secondary">
                                Charger: {selectedCharger || 'All'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey={null}>All</Dropdown.Item>
                                {distinctChargers.map(charger => (
                                    <Dropdown.Item key={charger} eventKey={charger}>{charger}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>

                {/* Averages Section */}
                <Row className="mb-4">
                    <Col md={2}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Total Revenue</Card.Title>
                                <h2>${averages.totalRevenue}</h2>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={2}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Sessions</Card.Title>
                                <h2>{averages.totalSessions}</h2>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={2}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Distinct Users</Card.Title>
                                <h2>{averages.distinctUsers}</h2>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={2}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Avg. kWh</Card.Title>
                                <h2>{averages.avgKwh}</h2>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={2}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Avg. Idle Time</Card.Title>
                                <h2>{averages.avgIdleTime} hrs</h2>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* Detailed Data Section */}
                <Row>
                    <Col>
                        <h4>Detailed Data</h4>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Location</th>
                                    <th>Group</th>
                                    <th>Charger</th>
                                    <th>Revenue</th>
                                    <th>Sessions</th>
                                    <th>User ID</th>
                                    <th>kWh</th>
                                    <th>Idle Time (hrs)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {propertyMetrics.map((metric, index) => (
                                    <tr key={index}>
                                        <td>{metric.location_id}</td>
                                        <td>{metric.group_id}</td>
                                        <td>{metric.charger_id}</td>
                                        <td>${metric.base_amount.toFixed(2)}</td>
                                        <td>1</td> {/* Each row is one session */}
                                        <td>{metric.user_id}</td>
                                        <td>{metric.kwh.toFixed(2)} kWh</td>
                                        
                                        <td>{((new Date(metric.session_end_time) - new Date(metric.charge_end_time)) / (1000 * 60 * 60)).toFixed(2)} hrs</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
    
                    {/* Insights Chart */}
                    <Row>
                        <Col>
                            <h4>Sessions Over Time</h4>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={propertyMetrics} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="transaction_dt" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="base_amount" stroke="#8884d8" activeDot={{ r: 8 }} />
                                    <Line type="monotone" dataKey="kwh" stroke="#82ca9d" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Col>
                    </Row>
    
                </Container>
            </div>
        );
    };
    
    export default PropertyManagerDashboard;
    